import { Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React  from "react";
import {FileInput, FunctionField} from "react-admin";
import {fileInputStyle} from "../style";


export const TrafficInstructionInput = ({ style, validate, source,props }) => {
  const classes = fileInputStyle();
  return (
    <div className={classes.container} style={style}>
      <Typography variant="subtitle1">Traffic Instructions</Typography>
      <Card className={classes.root} variant="outlined">
        <FileInput
          validate={validate}
          resource="trafficinstruction"
          source="filepath"
          className={classes.fileInput}
          label=""
          accept="application/pdf"
          helperText={false}
        >
          <FunctionField source="filepath" render={ r => r.rawFile && <a href={r.filepath} target="_blank" rel="noopener noreferrer">
            {r.rawFile.name}
          </a>}/>
        </FileInput>
      </Card>
    </div>
  )
}

TrafficInstructionInput.propTypes = {
  style: PropTypes.object,
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
  source: PropTypes.string,
}
