import {
  Create,
  required, SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import { createUpdateStyle } from "../../style";
import {EmailEditor, EmailEditorToolbar} from "../../components";
import * as React from "react";



export const EmailTemplateCreate = (props) => {
  const classes = createUpdateStyle();
  return (
      <div style={{display:'inline-flex'}}>
        <div style={{ width: "70%", height: "45%", marginTop: "24px" }}>
          <a className={classes.breadcrumbLink} href="#/emailtemplate">
            {"< Email Template"}
          </a>
          <Create title="Create Email Template" {...props} actions={false}>
            <SimpleForm toolbar={<EmailEditorToolbar isEdit={false} {...props} />}>
              <Typography variant="h6">Create Email Template</Typography>
              <br/>
              <SelectInput source="type" label="Template Type" choices={[
                { id: 'BRS', name: 'BRS' },
              ]} />
              <TextInput
                  className={classes.textField}
                  helperText={false}
                  source="subject"
                  validate={required()}
              />
              <br/>
              <EmailEditor source="body" isEdit={false}/>
            </SimpleForm>
          </Create>
        </div>
        <div style={{marginTop:"80px",width: "25%",marginLeft:"48px"}}>
          <Typography variant="h6">Available Variables</Typography>
          <br/>
          <Typography variant="h8">Subject</Typography>:
          <List>
            {
              ["{{ti_name}}}"].map((text) => <ListItem><ListItemText primary={text}/></ListItem>)
            }
          </List>
        </div>
      </div>
  );
};
