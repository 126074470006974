import {makeStyles} from "@material-ui/core/styles";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {
  AutocompleteInput,
  ChipField,
  TextInput,
  useGetList,
  useNotify,
  Button,
  useQuery,
  useSafeSetState, Loading
} from "react-admin";
import {useForm} from "react-final-form";
import difference from 'lodash/difference'


const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  listContainer: {
    width: "50%",
    padding: theme.spacing(1),
  },
  list: {
    height: theme.spacing(50),
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  },
  listHeader: {
    display: "block",
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
  },
  textField: {
    width: "100%",
    marginTop: 0,
  },
  textAreaField: {
    width: "100%",
    height: "100%",
    marginTop: 0,
  },
  hiddenTextField: {
    "& .MuiFilledInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiFormHelperText-contained": {
      display: "none",
    },
    display: "none",
    visibility: "hidden",
  },
  autocomplete: {
    width: "100%",
  },
  textarea: {
    height: "100%",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2.5),
  },
  textareaActions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(1),
  },
  outlinedButton: {
    marginTop:12,
    backgroundColor: "transparent",
    marginLeft: "16px",
    border: "1px solid #3CE7EA",
    color: "#3CE7EA",
    borderRadius: "unset",
    padding: "4px 8px 4px 8px",
    opacity: 0.9,
    "&:hover": {
      backgroundColor: "#3CE7EA",
      color: "#2A2A2A",
      opacity: 1,
      boxShadow: "none",
    },
    "&:disabled,button[disabled]": {
      borderColor: "#ffffff1f",
    },
    "& svg": {
      marginRight: "6px",
    },
  },
  button: {
    backgroundColor: "#3CE7EA",
    color: "#2A2A2A",
    borderRadius: "unset",
    padding: "4px 8px 4px 8px",
    opacity: 0.9,
    "&:hover": {
      backgroundColor: "#3CE7EA",
      color: "#2A2A2A",
      opacity: 1,
      boxShadow: "none",
    },
    "& svg": {
      marginRight: "6px",
      fontSize: "18px",
    },
  },
}));

export const BroadcastAutocompleteInput = (props) => {
  const form = useForm()
  const classes = useStyles();
  const [choices,setChoices] = useState([])
  const [isci, setIsci] = useState(null)
  const { brs_spots,input_spot } = form.getState().values
  useEffect(()=>{
    setIsci(null)
    let broadcasts = props.broadcastRequests;
    if (brs_spots?.length > 0) {
      const brs_spots_ids = brs_spots.map(spot=> spot.id)
      broadcasts = broadcasts.filter(broadcast => !brs_spots_ids.includes(broadcast.id))
      }
    setChoices(broadcasts)
  },[props.broadcastRequests])

  const handleAddBroadcastRequest = ()=>{
    const brs_spot = props.broadcastRequests.find(broadcastrequest =>{
      return broadcastrequest.id === input_spot
    })
    let spots = []
    if (brs_spots){
      spots = brs_spots
    }
    spots.push(brs_spot)
    const brs_spots_iscis = spots.map(spot=> spot.isciasset.isci)
    form.change("brs_spots", spots )
    form.change( "input_spot", null )
    const newChoices = choices.filter(choice => !brs_spots_iscis.includes(choice.isciasset.isci))
    setChoices(newChoices)
  }

  return (
            <div style={{display:"inline-flex"}}>
              <div>
                  <AutocompleteInput
                      className={classes.autocomplete}
                      choices={choices}
                      source="input_spot"
                      helperText={false}
                      allowEmpty={false}
                      translateChoice={false}
                      onChange={(value)=>setIsci(value)}
                      onBlur={(value)=>{
                        if (!value){
                          form.change("input_spot", null)
                          setIsci(null)
                        }
                      }}
                      label="Search for ISCI"
                      resettable
                      loading={props.loading}
                      optionText="isciasset.isci"
                      optionValue="id"
                  />
              </div>
              <div>
                <Button
                    label="add"
                    className={classes.outlinedButton}
                    disabled={input_spot ? false : true}
                    onClick={handleAddBroadcastRequest}
                />
              </div>

            </div>
  );
};
