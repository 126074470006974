import { makeStyles } from "@material-ui/core";
import React from "react";
import {
  CreateButton,
  Datagrid,
  DateField,
  Filter, List,
  Pagination,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";
import { StepperFieldJobBrs } from "../../components";
import { buttonStyle } from "../../style";

const useStyles = makeStyles((theme) => ({
  label: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
  },
  paddingLeft: {
    paddingLeft: "14px",
  },
}));

const BrsFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="request_id,broadcastrequests#isci@_ilike" alwaysOn/>
    </Filter>
  );
};

export const BrsListActions = (props) => {
  const classes = buttonStyle();
  return (
      <TopToolbar style={{marginLeft:"auto"}}>
        <CreateButton
            className={classes.button}
            label="New BRS Job"
            basePath="brs"
        />
      </TopToolbar>
  );
};


export const BrsList = (props) => {
  const classes = useStyles();
  return (
      <List
          perPage={500}
          pagination={<Pagination rowsPerPageOptions={[1,25, 50, 100, 250, 500]}/>}
          actions={<BrsListActions />}
          bulkActionButtons={false}
          title="BRS Dashboard"
          filters={<BrsFilter/>}
          resource="brsjob"
          basePath={"/brs"}
          sort={{ field: "created", order: "DESC" }}
          hasCreate
          hasEdit
          hasShow
          hasList
      >
        <Datagrid rowClick="show">
          <TextField source="request_id" label="ID" />
          <DateField source="created" label="Date Created" showTime />
          <TextField source="estimate_id"/>
          <StepperFieldJobBrs
              label={
                <div className={classes.label}>
                  <span>Preflight</span>
                  <span className={classes.paddingLeft}>Media</span>
                  <span>Notifications</span>
                </div>
              }
          ></StepperFieldJobBrs>
        </Datagrid>
      </List>
  );
};
