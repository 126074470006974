import gql from "graphql-tag";

export const EXTENDED_LOCATION = gql`
  {
    id
    name
    type
    bundle_files
    ftplocation {
      host
      password
      user
      ftp_location_id
      destination_path
      tls
    }
    locallocation {
      url
      password
      username
      local_location_id
      destination_path
    }
    sftplocation {
      host
      password
      user
      pk
      sftp_location_id
      destination_path
    }
    fasplocation {
      host
      password
      user
      fasp_location_id
      destination_path
    }
  }
`;

export const EXTENDED_TRANSCODER = gql`
  {
    id
    name
    type
    transcoderconfig {
      host
      port
      transcoder_id
      pathmappings {
        config_id
        dest
        id
        src
      }
    }
  }
`;

export const EXTENDED_FORMATS = gql`
  {
    id
    name
    ext
    output_dir
    discriminator
    workflow_id
    transcoder_id
    transcoder {
      id
      name
      type
    }
  }
`;

export const EXTENDED_JOB = gql`
  {
    updated
    status
    state
    id
    created
    batch_id
    job_type
    step_status
    step_history
    pending
    waiting
    reels {
      house_isci
    }
    pods {
      house_isci
    }
    brs_job {
      id
      step_status
      request_id
      estimate_id
      broadcastrequests {
        isci
      }
    }
  }
`;

export const EXTENDED_DELIVERY_FORMAT = gql`
  {
    created
    dam_cref
    format_id
    id
    isci
    job_id
    state
    status
    transcode_id
    transcode_percent
    transcoded_media_path
    updated
  }
`;

export const EXTENDED_DELIVERY_REQUEST = gql`
  {
    call_letters
    created
    format_id
    id
    isci
    job_id
    state
    status
    transfer_id
    transfer_percent
    updated
    transfer_group_id
    transfergroup {
      id
      state
      status
      transfer_id
      transfer_percent
      delivery_format_id
      retries
      updated
    }
    destination {
      format {
        name
      }
      location {
        name
      }
    }
  }
`;

export const EXTENDED_AUDIT_LOG = gql`
  {
    action
    created
    detail
    entity
    id
    job_id
    level
    job {
      batch_id
    }
  }
`;

export const EXTENDED_DESTINATION = gql`
  {
    id
    format_id
    location_id
    name
    call_letters
    created
    sd
    tags
    xml_sidecar
  }
`;
export const EXTENDED_DESTINATION_RECIPIENT = gql`
  {
    id
    destination_id
    recipient_id
    recipient {
      email
      id
      first_name
      last_name
    }
  }
`;

export const EXTENDED_RECIPIENT = gql`
  {
    id
    email
    first_name
    last_name
    destinations {
      id
      destination_id
      recipient_id
      traffic_instructions
      media_delivery
    }
  }
`;

export const EXTENDED_BROADCASTREQUEST = gql`
  {
    created
    id
    title
    isci
    brs_job_id
    isciasset {
      id
      isci
      duration
      sigma_encoding
      include_sd
      bvs_encoding
      original_iscis {
        id
        isci
        brand_name
        brand_code
        advertiser_name
        title
      }
    }
    step_status
    step_history
    brsjob {
      estimate_id
      request_id
    }
  }
`;

export const EXTENDED_BRSJOB = gql`
  {
    id
    request_id
    estimate_id
    market_type
    slate_type
    created
    step_status
    step_history
    job_id
    broadcastrequests {
      isciasset {
        isci
      }
    }
  }
`;

export const EXTENDED_BRSJOB_GET_ONE = gql`
  {
    id
    request_id
    estimate_id
    market_type
    slate_type
    created
    step_status
    step_history
    job_id
    inventory_source_name
    broadcastrequests {
      id
      isci
      step_history
      isciasset {
        id
        isci
        duration
        sigma_encoding
        include_sd
        bvs_encoding
        original_iscis {
          id
          isci
          brand_name
          brand_code
          advertiser_name
          title
        }
      }
    }
    destinations {
      notifications {
        id
        latest
      }
      id
      active
      updated
      destination {
        id
        name
        call_letters
      }
    }
    trafficinstructions(order_by: { created: desc }) {
      id
      version
      name
      created
      filepath
    }
  }
`;

export const EXTENDED_BRSNOTIFICATION = gql`
  {
    id
    status
    progress
    latest
    updated
    traffic_instruction_version
    brsjobdestination {
      id
      active
      destination {
        id
        name
        call_letters
      }
    }
    emails {
      email
      reason
      send_status
      opened_status
      updated
    }
  }
`;

export const EXTENDED_BRSJOBDESTINATION = gql`
  {
    id
    active
    destination {
      id
      name
      call_letters
    }
  }
`;

export const EXTENDED_BRANDS = gql`
  {
    id
    name
    code
  }
`;

export const EXTENDED_ISCIASSET = gql`
  {
    id
    duration
    isci
    sigma_encoding
    include_sd
    bvs_encoding
    original_iscis {
      id
      isci
      brand_name
      brand_code
      advertiser_name
      title
    }
  }
`;
