import { Datagrid, List, TextField } from "react-admin";
import { CustomPagination } from "../../components/CustomPagination";
import { FilterList, ListActions } from "../../components/index";

export const BrandList = (props) => {
  return (
      <List
          {...props}
          perPage={100}
          actions={<ListActions />}
          pagination={<CustomPagination rowsPerPageOptions={[25, 50, 100, 250, 500]}/>}
          filters={<FilterList />}
      >
        <Datagrid rowClick="edit">
          <TextField source="id" label="Brand ID" />
          <TextField source="name" label="Brand Name" />
          <TextField source="code" label="Brand Code" />
        </Datagrid>
      </List>
  );
};
