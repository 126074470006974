import { useRecordContext } from "react-admin";

const getStatus = (status,state,pending,waiting) => {
  if (waiting) {
    return "Awaiting Confirmation";
  }if (pending) {
    return "Pending";
  }
  if (status === "IN_PROGRESS") {
    return "In Progress";
  } else if (status === "COMPLETE") {
    if (state === "skipped"){
      return "Skipped"
    }
    return "Complete";
  } else if (status === "ERROR") {
    return "Fail";
  } else if (status === "CAUTION") {
    return "Caution";
  }
};

export const StatusField = (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  return <span>{getStatus(record?.[source],record?.state,record?.pending,record?.waiting)}</span>;
};


export const StatusTransferField = (props) => {
  const record = useRecordContext(props);
  const { source } = props;
  if(record?.[source]){
    return <span>{getStatus(record?.[source]?.status,record?.[source]?.state)}</span>;
  }
  return <StatusField {...props} source="status"/>
};
