import * as React from "react";
import { TextField, DateField } from "react-admin";
import { IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ShowController, ShowView, SimpleShowLayout } from "react-admin";

const DetailsAuditLogs = ({ onCancel, ...props }) => {
  return (
    <div>
      <div>
        <Typography
          variant="h6"
          style={{
            marginLeft: "28px",
            marginTop: "24px",
          }}
        >
          Audit Log Details
        </Typography>
        <IconButton
          style={{
            float: "right",
            position: "relative",
            top: "-38px",
            right: "20px",
          }}
          onClick={onCancel}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <ShowController {...props}>
        {(controllerProps) => (
          <ShowView
            title={" "}
            style={{ marginLeft: "16px" }}
            {...controllerProps}
          >
            <SimpleShowLayout>
              <DateField source="created" />
              <TextField source="entity.value.ISCI" label="ISCI" />
              <TextField source="action" />
              <TextField source="level" />
              <TextField source="detail" multiline />
            </SimpleShowLayout>
          </ShowView>
        )}
      </ShowController>
    </div>
  );
};

export default DetailsAuditLogs;
