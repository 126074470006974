import {buildFields} from "ra-data-hasura";
import {
  EXTENDED_AUDIT_LOG,
  EXTENDED_DELIVERY_FORMAT,
  EXTENDED_DELIVERY_REQUEST,
  EXTENDED_FORMATS,
  EXTENDED_JOB,
  EXTENDED_LOCATION,
  EXTENDED_TRANSCODER,
  EXTENDED_DESTINATION,
  EXTENDED_DESTINATION_RECIPIENT,
  EXTENDED_RECIPIENT,
  EXTENDED_BROADCASTREQUEST,
  EXTENDED_BRSJOB,
  EXTENDED_BRSNOTIFICATION,
  EXTENDED_BRSJOB_GET_ONE,
  EXTENDED_BRSJOBDESTINATION,
  EXTENDED_BRANDS, EXTENDED_ISCIASSET
} from "./query";

/**
 * Extracts just the fields from a GraphQL AST.
 * @param {GraphQL AST} queryAst
 */
const extractFieldsFromQuery = (queryAst) => {
  return queryAst.definitions[0].selectionSet.selections;
};

// Define the additional fields that we want.
export const customBuildFields = (type, fetchType) => {
  const resourceName = type.name;
  // First take the default fields (all, but no related or nested).
  const defaultFields = buildFields(type, fetchType);
  // Extend other queries for other resources/fetchTypes here...
  if (
      (resourceName === "location" && fetchType === "GET_ONE") ||
      (resourceName === "location" && fetchType === "GET_LIST")
  ) {
    const extendedLocationFields = extractFieldsFromQuery(EXTENDED_LOCATION);
    return extendedLocationFields;
  }
  if (
      (resourceName === "transcoder" && fetchType === "GET_ONE") ||
      (resourceName === "transcoder" && fetchType === "GET_LIST")
  ) {
    const extendedTranscoderFields = extractFieldsFromQuery(EXTENDED_TRANSCODER);
    return extendedTranscoderFields;
  }
  if (
      (resourceName === "format" && fetchType === "GET_ONE") ||
      (resourceName === "format" && fetchType === "GET_LIST")
  ) {
    const extendedFormatFields = extractFieldsFromQuery(EXTENDED_FORMATS);
    return extendedFormatFields;
  }
  if (
      (resourceName === "brand" && fetchType === "GET_ONE") ||
      (resourceName === "brand" && fetchType === "GET_LIST")
  ) {
    const extendedBrandFields = extractFieldsFromQuery(EXTENDED_BRANDS);
    return extendedBrandFields;
  }
  if (
      (resourceName === "job" && fetchType === "GET_LIST") ||
      (resourceName === "job" && fetchType === "GET_ONE")
  ) {
    const extendedJobFields = extractFieldsFromQuery(EXTENDED_JOB);
    return extendedJobFields;
  }
  if (resourceName === "deliveryformat" && fetchType === "GET_LIST") {
    const extendedDeliveryFormatFields = extractFieldsFromQuery(
        EXTENDED_DELIVERY_FORMAT
    );
    return extendedDeliveryFormatFields;
  }
  if (resourceName === "deliveryrequest" && fetchType === "GET_LIST") {
    const extendedDeliveryRequestFields = extractFieldsFromQuery(
        EXTENDED_DELIVERY_REQUEST
    );
    return extendedDeliveryRequestFields;
  }
  if (resourceName === "auditlog" && fetchType === "GET_LIST") {
    const extendedAuditLogFields = extractFieldsFromQuery(EXTENDED_AUDIT_LOG);
    return extendedAuditLogFields;
  }
  if (
      (resourceName === "destination" && fetchType === "GET_ONE") ||
      (resourceName === "destination" && fetchType === "GET_LIST")
  ) {
    const extendedDestinationFields =
        extractFieldsFromQuery(EXTENDED_DESTINATION);
    return extendedDestinationFields;
  }
  if (
      resourceName === "brsjobdestination" && fetchType === "GET_MANY_REFERENCE"
  ) {
    const extendedBrsJobDestinationFields =
        extractFieldsFromQuery(EXTENDED_BRSJOBDESTINATION);
    return extendedBrsJobDestinationFields;
  }
  if (
      (resourceName === "destinationrecipient" && fetchType === "GET_ONE") ||
      (resourceName === "destinationrecipient" && fetchType === "GET_LIST")
  ) {
    const extendedDestinationRecipientFields = extractFieldsFromQuery(
        EXTENDED_DESTINATION_RECIPIENT
    );
    return extendedDestinationRecipientFields;
  }
  if (
      (resourceName === "recipient" && fetchType === "GET_ONE") ||
      (resourceName === "recipient" && fetchType === "GET_LIST")
  ) {
    const extendedRecipientFields = extractFieldsFromQuery(EXTENDED_RECIPIENT);
    return extendedRecipientFields;
  }
  if (
      (resourceName === "broadcastrequest" && fetchType === "GET_ONE") ||
      (resourceName === "broadcastrequest" && fetchType === "GET_LIST")
  ) {
    const extendedBroadcastRequestFields = extractFieldsFromQuery(EXTENDED_BROADCASTREQUEST);
    return extendedBroadcastRequestFields;
  }
  if (
      (resourceName === "isciasset" && fetchType === "GET_ONE") ||
      (resourceName === "isciasset" && fetchType === "GET_LIST")
  ) {
    const extendedISCIAssetFields = extractFieldsFromQuery(EXTENDED_ISCIASSET);
    return extendedISCIAssetFields;
  }
  if (resourceName === "brsjob" && fetchType === "GET_ONE"){
    const extendedBRSJobOneFields = extractFieldsFromQuery(EXTENDED_BRSJOB_GET_ONE);
    return extendedBRSJobOneFields;
  }
  if(resourceName === "brsjob" && fetchType === "GET_LIST"){
    const extendedBRSJobListFields = extractFieldsFromQuery(EXTENDED_BRSJOB);
    return extendedBRSJobListFields;
  }
  if (resourceName === "brsnotification" && fetchType === "GET_LIST") {
    const extendedBRSNotificationFields = extractFieldsFromQuery(EXTENDED_BRSNOTIFICATION);
    return extendedBRSNotificationFields;
  }
  return defaultFields;
};

export default customBuildFields;
