import * as React from "react";
import Chip from '@material-ui/core/Chip';
import {makeStyles} from "@material-ui/core/styles";
const useStyles = makeStyles({
  opened_chip: (props)=>({
    marginRight:"4px",
    background:props.openStatusColor
  }),
  send_chip: (props)=>({
    marginRight:"4px",
    background:props.sendStatusColor
  }),
});

const getSendStatus = (status) => {
  if (status === "BOUNCED") {
    return "#EB5F5F";
  } else if (status === "ERROR") {
    return "#EB5F5F";
  } else if (status === "UNDELIVERED") {
    return "#ff9800";
  } else if (status === "DELIVERED") {
    return "#67B861";
  }
};
const getOpenStatus = (status) => {
  if (status === "PENDING") {
    return "#ff9800";
  } else if (status === "UNOPENED") {
    return "#ff9800";
  } else if (status === "OPENED") {
    return "#67B861";
  }
};
export const ChipField = (props) => {
  const sendStatusColor = getSendStatus(props.record.send_status)
  const openStatusColor = getOpenStatus(props.record.opened_status)
  const classes = useStyles({openStatusColor,sendStatusColor})
  return <><Chip classes={{root:classes.send_chip}} label={props.record.send_status}/> <Chip classes={{root:classes.opened_chip}}  label={props.record.opened_status}/></>;
}
