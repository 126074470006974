import {
  ArrayInput,
  AutocompleteInput,
  Button,
  FormDataConsumer,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  useCreate,
  useCreateSuggestionContext,
  useDataProvider,
  useNotify,
  useRedirect,
} from "react-admin";
import {
  checkOriginalIsciExists,
  getAssetData,
  triggerCreateBrsJob,
  triggerUpdateTI,
  uploadFile,
} from "../helpers";
import { CustomToolbar as BaseCustomToolbar } from "./CustomToolBar";
import { useForm } from "react-final-form";
import { useCallback, useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";
import last from "lodash/last";
import {
  Button as ButtonMUI,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@material-ui/core";
import { EditWithToolbarStyle } from "../style";
import * as React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import { FORM_ERROR } from "final-form";

export const BRSCustomToolbar = (props) => {
  const [create] = useCreate();
  const redirect = useRedirect();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const form = useForm();
  const onSave = async (data) => {
    let { filepath } = data;
    setLoading(true);
    data.traffic_instruction_version = "N/A";
    if (filepath) {
      const trafficInstructionUploadResponse = await uploadFile(
        `${window.REACT_APP_API_URL}/upload-traffic-instructions`,
        filepath,
      );
      notify("Traffic Instructions Uploaded Successfully", "info");
      data.filepath = trafficInstructionUploadResponse.key;
      data.file_name = filepath.rawFile.name;
    }
    if (props.tiUpdate) {
      if (!data.destinations_values) {
        data.destinations_values = data.destinations.map(
          (destination) => destination.destination.id,
        );
      }
      data.traffic_instruction_version = (
        data.trafficinstructions.length + 1
      ).toString();
      triggerUpdateTI(data).then((data) => {
        setLoading(false);
      });
    } else {
      data.traffic_instruction_version = 1;
      if (data.destinations_values.length === 0) {
        notify(
          `We are unable to submit this job please add a destination`,
          "warning",
        );
        return;
      }
      triggerCreateBrsJob(data).then((data) => {
        setLoading(false);
      });
    }
    redirect("/brs");
  };

  return (
    <>
      <BaseCustomToolbar
        isDisabled={loading}
        {...props}
        saveButtonExtraProps={{ ...(props.saveButtonExtraProps || {}), onSave }}
      />
      <Backdrop style={{ zIndex: 10000, color: "#3CE7EA" }} open={loading}>
        <div style={{ textAlign: "center" }}>
          <CircularProgress color="inherit" />
          <p>Please wait while we process your request.</p>
        </div>
      </Backdrop>
    </>
  );
};

export const EstimateIdInput = (props) => {
  const form = useForm();
  const dataProvider = useDataProvider();
  const [estimateId, setEstimateId] = useState("");

  const handleEstimateIdChange = async (event) => {
    const newEstimateId = event.target.value;
    setEstimateId(newEstimateId);
    await dataProvider
      .getList("brsjob", {
        filter: {
          "estimate_id@_eq": newEstimateId,
          "destinations#id@_is_null": false,
        },
        sort: {
          field: "created",
          order: "DESC",
        },
        pagination: {
          page: 1,
          perPage: 1,
        },
      })
      .then(({ data }) => {
        if (data.length > 0) {
          dataProvider
            .getManyReference("brsjobdestination", {
              target: "brs_job_id",
              id: data[0].id,
              sort: { field: "created", order: "DESC" },
            })
            .then(({ data }) => {
              form.change("destinations", data);
              form.change(
                "destinations_values",
                data.map((d) => d.destination.id),
              );
            });
        }
      });
  };

  return (
    <TextInput
      inputProps={{ maxLength: 7 }}
      {...props}
      value={estimateId}
      onChange={handleEstimateIdChange}
    />
  );
};

export const updateBRSFields = ({ value, form }) => {
  if (value === "FLUIDITY") {
    if (
      !form.getFieldState("request_id").touched ||
      !form.getFieldState("request_id").value
    ) {
      form.change("request_id", "0");
    }
    if (
      !form.getFieldState("estimate_id").touched ||
      !form.getFieldState("estimate_id").value
    ) {
      form.change("estimate_id", "0");
    }
    form.getFieldState("brs_spots").value.forEach(async (state, index) => {
      if (
        !form.getFieldState(`brs_spots[${index}].isciasset.sigma_encoding`)
          .touched
      ) {
        form.change(`brs_spots[${index}].sigma_encoding`, "NONE");
      }
      if (
        !form.getFieldState(`brs_spots[${index}].isciasset.bvs_encoding`)
          .touched
      ) {
        form.change(`brs_spots[${index}].bvs_encoding`, "NO");
      }
      if (
        !form.getFieldState(`brs_spots[${index}].isciasset.include_sd`).touched
      ) {
        form.change(`brs_spots[${index}].include_sd`, false);
      }
    });
  } else {
    form.getFieldState("brs_spots").value.forEach((state, index) => {
      if (
        !form.getFieldState(`brs_spots[${index}].isciasset.sigma_encoding`)
          .touched
      ) {
        form.change(`brs_spots[${index}].sigma_encoding`, "CADENT");
      }
      if (
        !form.getFieldState(`brs_spots[${index}].isciasset.bvs_encoding`)
          .touched
      ) {
        form.change(`brs_spots[${index}].isciasset.bvs_encoding`, "NO");
      }
      if (
        !form.getFieldState(`brs_spots[${index}].isciasset.include_sd`).touched
      ) {
        form.change(`brs_spots[${index}].isciasset.include_sd`, true);
      }
    });
  }
};

export const SlateTypeInput = ({ source, record = {}, ...rest }) => {
  const form = useForm();

  const handleSlateTypeChange = (event) => {
    const { value } = event.target;
    updateBRSFields({ value, form });
  };

  return (
    <SelectInput
      source={source}
      record={record}
      {...rest}
      onChange={handleSlateTypeChange}
    />
  );
};

export const getTotalDurationValue = (totalDurationObj) => {
  return Object.values(totalDurationObj).reduce((acc, curr) => {
    if (Number.isNaN(Number(curr))) {
      return acc;
    }
    return Number(acc) + Number(curr);
  }, 0);
};

export const IsciInput = ({
  setValidatingIsci,
  originalIscisField,
  setOriginalIscisDisabled,
  originalIscisDisabled,
  isciAssetSource,
  source,
  duration,
  originaliscis,
  disabled = false,
  ...props
}) => {
  const form = useForm();
  const isciValue = form.getFieldState(source)?.value;
  const [isci, setIsci] = useState("");
  const [validating, setValidating] = useState(false);

  const dataProvider = useDataProvider();
  useEffect(() => {
    if (isciValue && isci !== isciValue) {
      setIsci(isciValue);
    }
  }, [isciValue]);

  const getIsciAsset = async (isci) => {
    const br = dataProvider
      .getList("isciasset", { filter: { isci }, disctic_on: "isci" })
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        console.log(`${error}`, "warning");
      });
    return br;
  };

  const handleIsciBlur = async (event) => {
    let value = event.target.value;
    let IsIsciExist = true;
    const IsIsciExistOnTheForm = form
      .getState()
      .values.brs_spots.filter((spot) => spot?.isciasset.isci === isci);
    if (IsIsciExistOnTheForm.length > 1) {
      form.getFieldState(source).error = "ISCI already exist on the form";
      form.getFieldState(source).invalid = true;
      return;
    }
    if (value) {
      if (!value.endsWith("H")) {
        value = value + "H";
        setIsci(value);
        form.change(source, value);
      }
      setValidatingIsci(true);
      setValidating(true);
      console.log(value, form.getState().values.brs_spots);
      console.log(IsIsciExistOnTheForm);
      const isciAsset = await getIsciAsset(value);
      if (isciAsset.length > 0) {
        IsIsciExist = true;
        form.change(isciAssetSource, isciAsset[0]);
        form.change(`${isciAssetSource}.exists`, true);
      } else {
        IsIsciExist = false;
        form.change(`${isciAssetSource}.exists`, false);
        form.change(`${isciAssetSource}.original_iscis`, [{}]);
      }
      setValidating(false);
      setValidatingIsci(false);
      setOriginalIscisDisabled({
        ...originalIscisDisabled,
        [originalIscisField]: IsIsciExist,
      });
    } else {
      setOriginalIscisDisabled({
        ...originalIscisDisabled,
        [originalIscisField]: IsIsciExist,
      });
      if (form.getFieldState(originalIscisField) !== undefined) {
        form.change(originalIscisField, undefined);
      }
    }
  };

  return (
    <TextInput
      {...props}
      onBlur={handleIsciBlur}
      value={isci}
      source={source}
      inputProps={{ value: isci }}
      onChange={(event) => setIsci(event.target.value)}
      validate={[
        () => {
          if (isci.length === 0) {
            return "Required";
          }
        },
      ]}
      disabled={disabled}
      helperText={
        validating ? "Checking ISCI..." : form.getFieldState(source)?.error
      }
    />
  );
};

export const OriginalIsciInput = ({
  source,
  record = {},
  setValidatingOriginalIsci,
  getSource,
  disabled = false,
  durationSource,
  isciAssetExists,
  ...rest
}) => {
  const form = useForm();
  const [originalIsciValid, setOriginalIsciValid] = useState({});
  const [totalDuration, setTotalDuration] = useState({});
  const [validatingIsci, setValidatingIsci] = useState({
    index: null,
    value: false,
  });
  const [brandChoices, setBrandChoices] = useState([]);
  const dataProvider = useDataProvider();
  let totalDurationValue;
  if (!disabled) {
    totalDurationValue = getTotalDurationValue(totalDuration);
    if (form.getFieldState(durationSource)) {
      form.change(durationSource, totalDurationValue);
    }
  } else {
    totalDurationValue =
      form.getFieldState(getSource("isciasset.duration")) &&
      form.getFieldState(getSource("isciasset.duration")).value;
  }
  const originaIscis = form.getFieldState(source)?.value;
  const handleOriginalIsciBlur = useCallback(
    async (event, index, source, advName, title, duration) => {
      let { value } = event.target;
      const isci1 = value.endsWith("H") ? value : value + "H";
      const isci2 = value.endsWith("H") ? value.slice(0, -1) : value;
      const includeSDValue = form.getFieldState(
        getSource("isciasset.include_sd"),
      ).value;
      const args = includeSDValue ? [isci1, isci2] : [isci1];
      let items = args;
      if (value) {
        if (!value.endsWith("H")) {
          value = value + "H";
          items[index] = value;
          form.change(source, value);
        }
        setValidatingIsci({ index, value: true });
        setValidatingOriginalIsci(true);
        const originalIsciExists = await checkOriginalIsciExists(...args);
        if (originalIsciExists) {
          const assetData = (await getAssetData(value)) || { duration: 10 };
          if (assetData.duration && !Number.isNaN(assetData.duration)) {
            setTotalDuration({
              ...totalDuration,
              [`${duration}`]: assetData.duration,
            });
            if (!form.getFieldState(advName).value) {
              form.change(advName, assetData.brand || "N/A");
            }
            if (!form.getFieldState(title).value) {
              form.change(title, assetData.title || "N/A");
            }
          }
        }
        setOriginalIsciValid({
          ...originalIsciValid,
          [`${source}`]: originalIsciExists,
        });
        setValidatingOriginalIsci(false);
        setValidatingIsci({ index, value: false });
      } else {
        setOriginalIsciValid({ ...originalIsciValid, [`${source}`]: false });
        setTotalDuration({ ...totalDuration, [`${source}`]: undefined });
      }
    },
    [
      disabled,
      form,
      getSource,
      originalIsciValid,
      setValidatingOriginalIsci,
      source,
      totalDuration,
    ],
  );

  const validateOriginalIsci = (source) => {
    const value = form.getFieldState(source)?.value;
    if (originalIsciValid[source] === true) {
      return undefined;
    } else if (originalIsciValid[`${source}`] === false) {
      return "Original ISCI not found";
    } else if (!disabled && !value) {
      return "Required";
    }
    return undefined;
  };

  const isRequired = (source) => {
    const value = form.getFieldState(source)?.value;
    if (!disabled && !value && !isciAssetExists) {
      return "Required";
    }
    return undefined;
  };

  const handleBrandCode = async (brandId, nameField, codeField) => {
    //note that this nameField and codeField are not the name and code value itself
    const { data } = await dataProvider.getOne("brand", { id: brandId });
    if (data) {
      form.change(nameField, data.name);
      form.change(codeField, data.code);
    }
  };

  const getBrandChoices = async () => {
    const { data } = await dataProvider.getList("brand", {
      pagination: { page: 1, perPage: 500 },
    });
    const sortedDataAlphabetically = data.sort((a, b) =>
      a.name.localeCompare(b.name),
    );
    setBrandChoices(sortedDataAlphabetically);
  };
  useEffect(() => {
    getBrandChoices(); // Initial fetch when component mounts
  }, []);

  const getDefaultBrandID = (brand_name) => {
    if (brandChoices) {
      const defaultBrandObject = brandChoices.find(
        (item) => item.name === brand_name,
      );
      if (defaultBrandObject) {
        return defaultBrandObject.id;
      }
    }
  };
  const [selectedBrand, setSelectedBrand] = useState("");

  return (
    <div style={{ display: "inline-block" }}>
      <ArrayInput
        label=""
        style={{ marginRight: 4, display: "flex", borderBottom: null }}
        source={source}
      >
        <SimpleFormIterator
          TransitionProps={{ enter: false, exit: false }}
          className="form-iterator"
          disableReordering
          getItemLabel={() => ""}
          addButton={
            <Button
              label="ra.action.add"
              disabled={disabled}
              style={{ color: disabled ? "#ffffff4d" : "#3CE7EA" }}
            >
              <AddIcon />
            </Button>
          }
          removeButton={
            <Button
              label="ra.action.remove"
              style={{
                color: "#e57373",
                paddingTop: 12,
                marginLeft: 0,
                display: disabled ? "none" : "inline-block",
              }}
              onClick={(e, index) => {
                let newTotalDuration = { ...totalDuration };
                index = e.target.classList[5].replace(
                  `button-remove-${source}-`,
                  "",
                );
                delete newTotalDuration[`${source}[${index}].duration`];
                let newIndex = 0;
                const updatedTotalDuration = {};
                for (const key in newTotalDuration) {
                  updatedTotalDuration[`${source}[${newIndex}].duration`] =
                    newTotalDuration[key];
                  newIndex++;
                }
                setTotalDuration(() => updatedTotalDuration);
              }}
            >
              <CloseIcon />
            </Button>
          }
        >
          <FormDataConsumer>
            {({ getSource, scopedFormData, ...rest }) => {
              const matches = rest.id.match(/\[(.*?)\]/g);
              const lastMatch = last(matches);
              const index = Number(lastMatch.slice(1, -1));

              return (
                <Card style={{ display: "flex", width: "100%" }}>
                  <TextInput
                    validate={[(e) => validateOriginalIsci(getSource("isci"))]}
                    onBlur={(e) =>
                      handleOriginalIsciBlur(
                        e,
                        index,
                        getSource("isci"),
                        getSource("advertiser_name"),
                        getSource("title"),
                        getSource("duration"),
                      )
                    }
                    label={`Original ISCI ${index + 1}`}
                    source={getSource("isci")}
                    record={scopedFormData}
                    helperText={
                      validatingIsci.value && validatingIsci.index === index
                        ? "Checking ISCI..."
                        : ""
                    }
                    style={{ marginRight: "2rem", width: "25%" }}
                    disabled={disabled}
                  />
                  <TextInput
                    validate={[(e) => isRequired(getSource("title"))]}
                    source={getSource("title")}
                    record={scopedFormData}
                    label="Title"
                    style={{ marginRight: "2rem", width: "25%" }}
                    disabled={
                      !disabled &&
                      originalIsciValid.hasOwnProperty(getSource("isci"))
                        ? !originalIsciValid[getSource("isci")]
                        : true
                    }
                  />
                  <TextInput
                    validate={[(e) => isRequired(getSource("advertiser_name"))]}
                    source={getSource("advertiser_name")}
                    record={scopedFormData}
                    label="Advertiser Name"
                    style={{ marginRight: "2rem", width: "25%" }}
                    disabled={
                      !disabled &&
                      originalIsciValid.hasOwnProperty(getSource("isci"))
                        ? !originalIsciValid[getSource("isci")]
                        : true
                    }
                  />
                  {disabled ? (
                    <AutocompleteInput
                      disablePortal
                      label="Brand"
                      source={getSource("brand")}
                      record={scopedFormData}
                      defaultValue={
                        selectedBrand ||
                        getDefaultBrandID(scopedFormData?.brand_name)
                      }
                      choices={brandChoices}
                      onChange={(e) =>
                        handleBrandCode(
                          e,
                          getSource("brand_name"),
                          getSource("brand_code"),
                        )
                      }
                      optionText="name"
                      create={
                        <CreateBrandModal
                          getBrandChoices={getBrandChoices}
                          handleBrandCode={handleBrandCode}
                          selectedBrand={selectedBrand}
                          setSelectedBrand={setSelectedBrand}
                          nameField={getSource("brand_name")}
                          codeField={getSource("brand_code")}
                        />
                      }
                      PopperProps={{
                        modifiers: {
                          flip: {
                            enabled: false, // Prevent flipping to other sides
                          },
                          preventOverflow: {
                            enabled: true,
                            boundariesElement: "viewport", // Keep dropdown within the viewport
                          },
                        },
                        placement: "bottom", // Force dropdown to open downward
                      }}
                    />
                  ) : (
                    <ReferenceInput
                      disablePortal
                      validate={[required()]}
                      source={getSource("brand")}
                      record={scopedFormData}
                      label="Brand"
                      perPage={500}
                      sort={{ field: "name", order: "ASC" }}
                      style={{ marginLeft: "2rem", width: "15%" }}
                      onChange={(e) =>
                        handleBrandCode(
                          e,
                          getSource("brand_name"),
                          getSource("brand_code"),
                        )
                      }
                      disabled={
                        !disabled &&
                        originalIsciValid.hasOwnProperty(getSource("isci"))
                          ? !originalIsciValid[getSource("isci")]
                          : true
                      }
                      reference="brand"
                    >
                      <AutocompleteInput
                        disablePortal
                        optionText="name"
                        create={
                          <CreateBrandModal
                            getBrandChoices={getBrandChoices}
                            handleBrandCode={handleBrandCode}
                            selectedBrand={selectedBrand}
                            setSelectedBrand={setSelectedBrand}
                            nameField={getSource("brand_name")}
                            codeField={getSource("brand_code")}
                          />
                        }
                        PopperProps={{
                          modifiers: {
                            flip: {
                              enabled: false, // Prevent flipping to other sides
                            },
                            preventOverflow: {
                              enabled: true,
                              boundariesElement: "viewport", // Keep dropdown within the viewport
                            },
                          },
                          placement: "bottom", // Force dropdown to open downward
                        }}
                      />
                    </ReferenceInput>
                  )}

                  <TextInput
                    validate={[(e) => isRequired(getSource("brand_code"))]}
                    source={getSource("brand_code")}
                    record={scopedFormData}
                    label="Brand Code"
                    style={{
                      marginLeft: "2rem",
                      marginRight: "2rem",
                      width: "15%",
                    }}
                    disabled={
                      originalIsciValid.hasOwnProperty(getSource("isci"))
                        ? !originalIsciValid[getSource("isci")]
                        : true
                    }
                  />
                </Card>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
      {originaIscis && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "0.5rem",
            marginLeft: "1rem",
          }}
        >
          <span>Total Duration: {totalDurationValue}</span>
        </div>
      )}
    </div>
  );
};

export const CreateBrandModal = ({
  getBrandChoices,
  handleBrandCode,
  selectedBrand,
  setSelectedBrand,
  nameField,
  codeField,
}) => {
  const classes = EditWithToolbarStyle();
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const notify = useNotify();
  const [create] = useCreate("brand");
  const [name, setName] = React.useState(filter || "");
  const [code, setCode] = React.useState(filter || "");
  const handleSubmit = (event, newBrand) => {
    event.preventDefault();

    new Promise((resolve, reject) => {
      create(
        {
          payload: {
            data: {
              name,
              code,
            },
          },
        },
        {
          onSuccess: resolve,
          onFailure: reject,
        },
      );
    })
      .then(({ data }) => {
        notify("Brand created successfully", { type: "info" });
        onCreate(getBrandChoices());
        handleBrandCode(data.id, nameField, codeField);
        setName("");
        setCode("");
        //this is to set the field value dynamically to the brand just created
        setSelectedBrand(data.id);
      })
      .catch((error) => {
        console.error("Error creating brand:", error);
      });
  };

  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent style={{ padding: 24 }}>
          <h4>Create Brand</h4>
          <div>
            <TextField
              label="Brand"
              value={name}
              onChange={(event) => setName(event.target.value)}
              autoFocus
            />
          </div>
          <br />
          <div>
            <TextField
              label="Brand Code"
              value={code}
              onChange={(event) => setCode(event.target.value)}
            />
          </div>
          <br />
        </DialogContent>
        <DialogActions
          style={{ float: "left", marginLeft: 14, marginBottom: 8 }}
        >
          <ButtonMUI className={classes.editButton} type="submit">
            Save
          </ButtonMUI>
          <ButtonMUI className={classes.cancelButton} onClick={onCancel}>
            Cancel
          </ButtonMUI>
        </DialogActions>
      </form>
    </Dialog>
  );
};
