import { Card } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Button,
  Create,
  DateInput,
  Edit,
  FormDataConsumer,
  Loading,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useDataProvider,
  useGetList,
  useRecordContext,
} from "react-admin";
import {
  BroadcastAutocompleteInput,
  DestinationListInput,
  TrafficInstructionInput,
} from "../../components";
import { alphanumeric } from "../../helpers";
import { createUpdateStyle } from "../../style";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  BRSCustomToolbar,
  EstimateIdInput,
  IsciInput,
  OriginalIsciInput,
  SlateTypeInput,
} from "../../components/BrsFormComponents";
import { Button as ButtonMUI } from "@material-ui/core";
import { BroadcastRequestDialog } from "../../components/BroadcastRequestDialog";
import { FormSpy, useForm } from "react-final-form";

export const TrafficInstructionEdit = (props) => {
  const dataProvider = useDataProvider();
  const classes = createUpdateStyle();
  const [validatingIsci, setValidatingIsci] = useState(true);
  const [validatingOriginalIsci, setValidatingOriginalIsci] = useState(true);
  const [originalIscisDisabled, setOriginalIscisDisabled] = useState({});
  const [unitRotation, setUnitRotation] = useState("unit");
  const [includeSD, setIncludeSD] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [brsJobData, setBrsJobData] = useState(null);
  const handleIncludeSDChange = useCallback(
    (field) => async (value) => {
      setIncludeSD({ ...includeSD, [field]: value });
    },
    [includeSD],
  );

  useEffect(() => {
    if (data.length === 0 && props.match.params.id) {
      setLoading(false);
      dataProvider
        .getOne("brsjob", { id: props.match.params.id })
        .then(({ data }) => {
          setBrsJobData(data);
          dataProvider
            .getList("broadcastrequest", {
              pagination: { page: 1, perPage: 10000 },
              sort: { field: "isciasset_id", order: "ASC" },
              filter: {
                distinct_on: "isciasset_id",
                "brsjob#request_id": data.request_id,
              },
            })
            .then(({ data }) => {
              setLoading(false);
              setData(data);
              return data;
            });
        });
    }
  }, []);

  const refetch = () => {
    setLoading(true);
    dataProvider
      .getOne("brsjob", { id: props.match.params.id })
      .then(({ data }) => {
        setBrsJobData(data);
        dataProvider
          .getList("broadcastrequest", {
            pagination: { page: 1, perPage: 10000 },
            sort: { field: "isciasset_id", order: "ASC" },
            filter: {
              distinct_on: "isciasset_id",
              "brsjob#request_id": data.request_id,
            },
          })
          .then(({ data }) => {
            setLoading(false);
            setData(data);
            return data;
          });
      });
  };

  const handleDialog = () => {
    setOpenDialog(!openDialog);
  };
  return brsJobData ? (
    <div className={classes.card} style={{ width: "100%" }}>
      <a className={classes.breadcrumbLink} href="#/brs">
        {"< BRS Job"}
      </a>
      <Edit
        title="Traffic Instruction Update"
        resource="brsjob"
        basePath={"/traffic-instruction"}
        id={props.match.params.id}
        {...props}
      >
        <SimpleForm
          redirect="list"
          toolbar={<BRSCustomToolbar {...props} tiUpdate={true} />}
          validateOnBlur={true}
        >
          <Typography variant="h6">Update Requests</Typography>
          <Card style={{ display: "flex", width: "100%" }}>
            <TextInput
              validate={[required()]}
              className={classes.textField}
              source="request_id"
              label="Request ID"
              style={{ marginRight: "2rem", width: "25%" }}
              disabled={true}
            />
            <EstimateIdInput
              validate={[required()]}
              className={classes.textField}
              source="estimate_id"
              label="Estimate ID"
              style={{ marginRight: "2rem", width: "25%" }}
              disabled={true}
            />
            <SlateTypeInput
              source="slate_type"
              validate={[required()]}
              choices={[
                { id: "CADENT", name: "Cadent" },
                { id: "CROSS", name: "Cross" },
                {
                  id: "FLUIDITY",
                  name: "Fluidity",
                },
              ]}
              className={classes.textField}
              defaultValue="CADENT"
              style={{ marginRight: "2rem", width: "25%" }}
              disabled={true}
            />
            <SelectInput
              source="market_type"
              validate={[required()]}
              choices={[
                { id: "LOCAL", name: "Local" },
                { id: "NATIONAL", name: "National" },
              ]}
              className={classes.textField}
              defaultValue="LOCAL"
              style={{
                marginRight: "2rem",
                width: "25%",
              }}
              disabled={true}
            />
          </Card>
          <Card style={{ display: "inline-flex", width: "100%" }}>
            <div style={{ marginRight: "2rem", width: "23%" }}>
              <TextInput
                source="inventory_source_name"
                label="Inventory Source"
                disabled={true}
              />
            </div>
            <div style={{ marginRight: "2rem", width: "23%" }}>
              <SelectInput
                source="unit_rotation"
                choices={[
                  { id: "unit", name: "Unit" },
                  { id: "rotation", name: "Rotation" },
                ]}
                onChange={(e) => setUnitRotation(e.target.value)}
                defaultValue="unit"
                label="Unit/Rotation"
                className={classes.textField}
                validate={[required()]}
                disabled={true}
              />
            </div>
          </Card>
          <Card style={{ width: "98.6%", padding: 10 }} variant="outlined">
            <Typography variant="h6">Broadcast Requests</Typography>
            <div style={{ display: "inline-flex" }}>
              <BroadcastAutocompleteInput
                broadcastRequests={Object.values(data)}
                style={{ width: "50%", marginRight: 24 }}
                resource="broadcastrequest"
                loading={loading}
              />
              <div>
                <ButtonMUI
                  style={{ marginTop: 11, marginLeft: 10 }}
                  color="secondary"
                  onClick={handleDialog}
                  variant="outlined"
                >
                  Bulk Add
                </ButtonMUI>
              </div>
            </div>

            <ArrayInput
              source="brs_spots"
              label=""
              validate={[required()]}
              helperText="Add ISCIs individually, or multiple ISCIs using the Bulk Add"
            >
              <SimpleFormIterator
                TransitionProps={{ enter: false, exit: false }}
                variant="filled"
                addButton={
                  <Button label="ra.action.add" style={{ display: "none" }}>
                    <AddIcon />
                  </Button>
                }
                removeButton={
                  <Button
                    label="ra.action.remove"
                    onClick={() => {
                      refetch();
                    }}
                    style={{ color: "#e57373", marginTop: 12, marginRight: 8 }}
                  >
                    <CloseIcon />
                  </Button>
                }
              >
                <FormDataConsumer>
                  {({ getSource, scopedFormData }) => {
                    if (unitRotation === "rotation" && scopedFormData?.unit) {
                      scopedFormData.unit = null;
                    } else if (
                      unitRotation === "unit" &&
                      scopedFormData?.rotation
                    ) {
                      scopedFormData.rotation = null;
                    }
                    return (
                      <>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            marginTop: "8px",
                          }}
                        >
                          <DateInput
                            className={classes.textField}
                            validate={[required()]}
                            source={getSource("start_date")}
                            record={scopedFormData}
                            label="Start Date"
                            style={{ marginRight: "2rem", width: "20%" }}
                            InputProps={{
                              style: {
                                "::-webkit-calendar-picker-indicator": {
                                  color: "red",
                                },
                              },
                            }}
                          />
                          <DateInput
                            className={classes.textField}
                            validate={[required()]}
                            source={getSource("end_date")}
                            record={scopedFormData}
                            label="End Date"
                            style={{ marginRight: "2rem", width: "20%" }}
                          />
                          <TextInput
                            source={getSource("title")}
                            record={scopedFormData}
                            label="Title"
                            style={{ marginRight: "2rem", width: "20%" }}
                            disabled={false}
                          />
                        </div>
                        <div style={{ display: "flex", width: "100%" }}>
                          <IsciInput
                            className={classes.textField}
                            validate={[required(), alphanumeric]}
                            source={getSource("isciasset.isci")}
                            record={scopedFormData}
                            label="ISCI"
                            style={{ marginRight: "2rem", width: "25%" }}
                            setValidatingIsci={setValidatingIsci}
                            setOriginalIscisDisabled={setOriginalIscisDisabled}
                            originalIscisDisabled={originalIscisDisabled}
                            originalIscisField={getSource("isciasset.isci")}
                            duration={getSource("isciasset.duration")}
                            originaliscis={getSource(
                              "isciasset.original_iscis",
                            )}
                            disabled={true}
                          />
                          <SelectInput
                            source={getSource("isciasset.sigma_encoding")}
                            choices={[
                              { id: "NONE", name: "None" },
                              { id: "CADENT", name: "Cadent" },
                              {
                                id: "TTWN",
                                name: "TTWN",
                              },
                            ]}
                            defaultValue="CADENT"
                            label="Sigma Encoding"
                            className={classes.textField}
                            validate={[required()]}
                            style={{ marginRight: "2rem", width: "25%" }}
                            disabled={true}
                          />
                          <SelectInput
                            choices={[
                              { id: "YES", name: "Yes" },
                              { id: "NO", name: "No" },
                            ]}
                            defaultValue="NO"
                            label="BVS Encoding"
                            className={classes.textField}
                            validate={[required()]}
                            source={getSource("isciasset.bvs_encoding")}
                            style={{ marginRight: "2rem", width: "25%" }}
                            disabled={true}
                          />
                          <NumberInput
                            className={classes.textField}
                            style={{ display: "none" }}
                            source={getSource("isciasset.duration")}
                            record={scopedFormData}
                            helperText={false}
                          />
                          <TextInput
                            className={classes.textField}
                            validate={[required()]}
                            source={getSource(unitRotation)}
                            record={scopedFormData}
                            label={unitRotation}
                            style={{ width: "12%" }}
                            onChange={(e) => {
                              scopedFormData[unitRotation] = e.target.value;
                            }}
                            InputProps={{
                              endAdornment:
                                unitRotation === "rotation" ? (
                                  <InputAdornment position="start">
                                    %
                                  </InputAdornment>
                                ) : null,
                            }}
                          />
                          <BooleanInput
                            defaultValue={true}
                            className={classes.switch}
                            source={getSource("isciasset.include_sd")}
                            record={scopedFormData}
                            validate={[required()]}
                            onChange={handleIncludeSDChange(
                              getSource("include_sd"),
                            )}
                            label="Include SD"
                            style={{
                              margin: "1rem 2rem 0rem 2rem",
                              width: "25%",
                            }}
                            disabled={true}
                          />
                        </div>
                        <div style={{ display: "flex", width: "96%" }}>
                          <OriginalIsciInput
                            source={getSource("isciasset.original_iscis")}
                            nestedLabel="Original ISCI"
                            maxLength={4}
                            setValidatingOriginalIsci={
                              setValidatingOriginalIsci
                            }
                            getSource={getSource}
                            disabled={true}
                          />
                        </div>
                      </>
                    );
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </Card>
          <Card style={{ display: "flex", width: "100%", margin: "10px 0" }}>
            <DestinationListInput
              brsDestinations={brsJobData.destinations.filter(
                (destination) => destination.active === true,
              )}
              style={{ width: "45%" }}
              showDestinationTextArea={false}
              source="destinations"
              resource="destination"
              sort={{ field: "call_letters", order: "ASC" }}
              canDelete={true}
              isCreate={false}
            />
            <TrafficInstructionInput
              validate={[required()]}
              source="trafficinstructions"
              style={{ width: "50%" }}
            />
          </Card>
          {openDialog && (
            <BroadcastRequestDialog
              broadcastRequests={Object.values(data)}
              handleDialog={handleDialog}
            />
          )}
        </SimpleForm>
      </Edit>
    </div>
  ) : (
    <Loading />
  );
};
