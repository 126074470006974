import axios from "axios";
import { useEffect, useRef } from "react";

export const buildMutationLocation = (params) => {
  const data = params.data;
  const type = data.type;
  const getVariables = () => {
    const variables = {
      password: data[`${type}location`].password,
      user: data[`${type}location`].user,
      host: data[`${type}location`].host,
      destination_path: data[`${type}location`].destination_path,
    };
    if (type === "ftp") {
      return {...variables, tls:data.tls};
    } else if (type === "fasp")  {
      return variables
    } else if (type === "sftp") {
      return {
        ...variables,
        pk: data[`${type}location`].pk,
      };
    } else if (type === "local") {
      return {
        password: data[`${type}location`].password,
        username: data[`${type}location`].username,
        url: data[`${type}location`].url,
        destination_path: data[`${type}location`].destination_path,
      };
    }
  };
  return {
    objects: {
      name: data.name,
      type: type,
      bundle_files: data.bundle_files,
      [`${type}location`]: {
        data: getVariables(),
      },
    },
  };
};

export const buildCreateLocation = (params) => {
  const data = params.data;
  const type = data.type;
  const getVariables = () => {
    const variables = {
      password: data.password,
      user: data.user,
      host: data.host,
      destination_path: data.destination_path,
      [`${data.type}_location_id`]: data.id,
    };
    if (type === "ftp") {
      return {...variables, tls:data.tls};
    } else if (type === "fasp")  {
      return variables
    } else if (type === "sftp") {
      return {
        ...variables,
        pk: data.pk,
      };
    } else if (type === "local") {
      return {
        password: data.password,
        username: data.username,
        url: data.url,
        destination_path: data.destination_path,
        [`${data.type}_location_id`]: data.id,
      };
    }
  };
  return {
    objects: {
      ...getVariables(),
    },
  };
};

export const useRecursiveTimeout = (callback, delay) => {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    let id;
    function tick() {
      const ret = savedCallback.current();

      if (ret instanceof Promise) {
        ret.then(() => {
          if (delay !== null) {
            id = setTimeout(tick, delay);
          }
        });
      } else {
        if (delay !== null) {
          id = setTimeout(tick, delay);
        }
      }
    }
    if (delay !== null) {
      id = setTimeout(tick, delay);
      return () => id && clearTimeout(id);
    }
  }, [delay]);
};

export const uploadFile = async (url, file) => {
  const formData = new FormData();
  formData.append('file', file.rawFile);
  try {
    const response = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error('Failed to upload file');
  }
};



export const checkOriginalIsciExists = async (isci1, isci2) => {
  try {
    const response = await axios.get(
      `${window.REACT_APP_API_URL}/validate-original-isci`,
      {
        params: {
          isci1,
          isci2,
        }
      }
    );

    return response.data.found;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export const getAssetData = async (isci) => {
  try {
    const response = await axios.get(
      `${window.REACT_APP_API_URL}/asset/${isci}`,
    );

    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}


export const triggerCreateBrsJob = async (data) => {
  try {
    const response = await axios.post(
        `${window.REACT_APP_API_URL}/api/v1/brs-create`,
        {
          brs_job: {...data}
        }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export const triggerMediaJobWorkflow = async (jobId, brsJobId) => {
  try {
    const response = await axios.post(
        `${window.REACT_APP_API_URL}/workflow/media-job`,
        {
          id: brsJobId,
          job_id: jobId
        }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export const triggerUpdateTI = async (data) => {
  try {
    const response = await axios.post(
        `${window.REACT_APP_API_URL}/api/v1/update-traffic-instructions`,
        {
          brs_job: {...data}
        }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export const triggerAddonsUpdate = async (brsJobId, jobId, destination_ids) => {
  try {
    const response = await axios.post(
        `${window.REACT_APP_API_URL}/workflow/update-addons`,
        {
          id: brsJobId,
          job_id: jobId,
          destination_ids:destination_ids
        }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export const triggerRetryReel = async (jobType, jobId, reelId) => {
  try {
    const response = await axios.post(
        `${window.REACT_APP_API_URL}/workflow/retry-reel`,
        {
          job_type: jobType,
          reel_id: reelId,
          job_id: jobId
        }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
}
export const triggerRetryPod = async (jobType, jobId, podId) => {
  try {
    const response = await axios.post(
        `${window.REACT_APP_API_URL}/workflow/retry-pod`,
        {
          job_type: jobType,
          pod_id: podId,
          job_id: jobId
        }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export const triggerRetryBrs = async (brsJobId,jobId,broadcastId) => {
  try {
    const response = await axios.post(
        `${window.REACT_APP_API_URL}/workflow/retry-brs`,
        {
          broadcast_id: broadcastId,
          brs_job_id: brsJobId,
          job_id: jobId
        }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export const triggerRetryTranscode = async (jobId,brsJobId, dfmtId, jobType) => {
  try {
    const response = await axios.post(
        `${window.REACT_APP_API_URL}/workflow/retry-transcode`,
        {
          dfmt_id: dfmtId,
          brs_job_id :brsJobId,
          job_id: jobId,
          job_type: jobType
        }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export const triggerRetryTransfer = async (jobId,brsJobId,transferGroupId, dfmtId, jobType) => {
  try {
    const response = await axios.post(
        `${window.REACT_APP_API_URL}/workflow/retry-transfer`,
        {
          job_id: jobId,
          brs_job_id:brsJobId,
          transfer_group_id:transferGroupId,
          delivery_format_id:dfmtId,
          job_type: jobType
        }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export const createEmailTemplate = (emailTemplate) => new Promise(async (resolve, reject) => {
  try {
    const response = await axios.post(
        `${window.REACT_APP_API_URL}/email_template/create`,
        {
          etemp:emailTemplate
        }
    );
    resolve(response.data);
  } catch (error) {
    reject(error.response);
  }
})

export const updateEmailTemplate= (emailTemplate) => new Promise((resolve, reject) => {
  try {
    const response = axios.post(
        `${window.REACT_APP_API_URL}/email_template/update`,
        {
          etemp: emailTemplate
        }
    );
    resolve(response.data);
  } catch (error) {
    reject(error.response);
  }
})

export const getEmailTemplate = (emailTemplateId) => new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(
        `${window.REACT_APP_API_URL}/email_template/${emailTemplateId}`,
    );
    resolve(response.data);
  } catch (error) {
    reject(error.response);
  }
})

export const triggerPodWorkflow = async (fileName,pods) => {
  try {
    const response = await axios.post(
        `${window.REACT_APP_API_URL}/workflow/pods`,
        {
          filename: fileName,
          pods
        }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export const triggerLocalPodWorkflow = async (jobId,batchId) => {
  try {
    const response = await axios.post(
        `${window.REACT_APP_API_URL}/workflow/local-pods-start`,
        {
          job_id: jobId,
          batch_id:batchId
        }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

const convertFileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file.rawFile);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

export const triggerValidatePodFile = async (fileData) => {
  const file = await convertFileToBase64(fileData)
  try {
    const response = await axios.post( `${window.REACT_APP_API_URL}/validate-pod-file`, {file_data:file}, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error('Failed to upload file');
  }
};

export const alphanumeric = (value) => {
  if (!/^[a-zA-Z0-9]*$/.test(value)) {
    return 'Must be alphanumeric';
  }
  return undefined;
};

export const removeUntilFirstSlash = (inputString)=> {
  const lastIndex = inputString.lastIndexOf('/');
  if (lastIndex > 1) {
    return inputString.substring(0, lastIndex);
  } else {
    // If there is no '/', return the original string
    return inputString;
  }
}
