import CustomPaginationActions from "./CustomPaginationActions";
import { Pagination } from "react-admin";

export const CustomPagination = (props) => (
  <Pagination
    ActionsComponent={CustomPaginationActions}
    rowsPerPageOptions={props.rowsPerPageOptions}
    {...props}
  />
);
