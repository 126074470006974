/*eslint no-unused-vars: */
import omit from "lodash/omit";
import set from "lodash/set";
import { buildCreateLocation, buildMutationLocation } from "../helpers";
import {
  CREATE,
  DELETE,
  DELETE_MANY,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
  UPDATE_MANY,
} from "./fetchActions";
import getFinalType from "./getFinalType";

const SPLIT_TOKEN = '#';
const MULTI_SORT_TOKEN = ',';
const SPLIT_OPERATION = '@';

export const buildGetListVariables= () => (resource, _, params) => {
  const result= {};
  let { filter: filterObj = {} } = params;
  const { customFilters = [] } = params;

  const distinctOnField = 'distinct_on';
  /** Setting "distinct_on" to be the `filters` object attribute to be used inside RA
   * and setting to a `distinct_on` variable
   * and removing from the filter object
   */
  const { distinct_on = '' } = filterObj;
  filterObj = omit(filterObj, [distinctOnField]);

  /**
   * Nested entities are parsed by CRA, which returns a nested object
   * { 'level1': {'level2': 'test'}}
   * instead of { 'level1.level2': 'test'}
   * That's why we use a HASH for properties, when we declared nested stuff at CRA:
   * level1#level2@_ilike
   */

  /**
   keys with comma separated values
   {
   'title@ilike,body@like,authors@similar': 'test',
   'col1@like,col2@like': 'val'
   }
   */
  const orFilterKeys = Object.keys(filterObj).filter((e) => e.includes(','));

  /**
   format filters
   {
   'title@ilike': 'test',
   'body@like': 'test',
   'authors@similar': 'test',
   'col1@like': 'val',
   'col2@like': 'val'
   }
   */
  const orFilterObj = orFilterKeys.reduce((acc, commaSeparatedKey) => {
    const keys = commaSeparatedKey.split(',');
    return {
      ...acc,
      ...keys.reduce((acc2, key) => {
        return {
          ...acc2,
          [key]: filterObj[commaSeparatedKey],
        };
      }, {}),
    };
  }, {});
  filterObj = omit(filterObj, orFilterKeys);

  const makeNestedFilter = (obj, operation) => {
    if (Object.keys(obj).length === 1) {
      const [key] = Object.keys(obj);
      return { [key]: makeNestedFilter(obj[key], operation) };
    } else {
      return { [operation]: obj };
    }
  };

  const filterReducer = (obj) => (acc, key) => {
    let filter;
    if (key === 'ids') {
      filter = { id: { _in: obj['ids'] } };
    } else if (Array.isArray(obj[key])) {
      let [keyName, operation = '_in', opPath] = key.split(SPLIT_OPERATION);
      let value = opPath
          ? set({}, opPath.split(SPLIT_TOKEN), obj[key])
          : obj[key];
      filter = set({}, keyName.split(SPLIT_TOKEN), { [operation]: value });
    } else if (obj[key] && obj[key].format === 'hasura-raw-query') {
      filter = set({}, key.split(SPLIT_TOKEN), obj[key].value || {});
    } else {
      let [keyName, operation = ''] = key.split(SPLIT_OPERATION);
      let operator;
      if (operation === '{}') operator = {};
      const field = resource.type.fields.find((f) => f.name === keyName);
      if (field) {
        switch (getFinalType(field.type).name) {
          case 'String':
            operation = operation || '_ilike';
            if (!operator)
              operator = {
                [operation]: operation.includes('like')
                    ? `%${obj[key]}%`
                    : obj[key],
              };
            break;
          case 'jsonb':
            try {
              const parsedJSONQuery = JSON.parse(obj[key]);
              if (parsedJSONQuery) {
                operator = {
                  [operation || '_contains']: parsedJSONQuery
                };
              }
            } catch (ex) {}
            break;
          default:
            if (!operator)
              operator = {
                [operation || '_eq']: operation.includes('like')
                    ? `%${obj[key]}%`
                    : obj[key],
              };
        }
      } else {
        // Else block runs when the field is not found in Graphql schema.
        // Most likely it's nested. If it's not, it's better to let
        // Hasura fail with a message than silently fail/ignore it
        if (!operator)
          operator = {
            [operation || '_eq']: operation.includes('like')
                ? `%${obj[key]}%`
                : obj[key],
          };
      }
      filter = set({}, keyName.split(SPLIT_TOKEN), operator);
    }
    return [...acc, filter];
  };
  const andFilters = Object.keys(filterObj)
      .reduce(filterReducer(filterObj), customFilters)
      .filter(Boolean);
  const orFilters = Object.keys(orFilterObj)
      .reduce(filterReducer(orFilterObj), [])
      .filter(Boolean);

  result['where'] = {
    _and: andFilters,
    ...(orFilters.length && { _or: orFilters }),
  };

  if (params.pagination && params.pagination.perPage > -1) {
    result['limit'] = parseInt(params.pagination.perPage, 10);
    result['offset'] =
        (params.pagination.page - 1) * params.pagination.perPage;
  }

  if (params.sort) {
    const { field, order } = params.sort;
    const hasMultiSort =
        field.includes(MULTI_SORT_TOKEN) || order.includes(MULTI_SORT_TOKEN);
    if (hasMultiSort) {
      const fields = field.split(MULTI_SORT_TOKEN);
      const orders = order
          .split(MULTI_SORT_TOKEN)
          .map((order) => order.toLowerCase());

      if (fields.length !== orders.length) {
        throw new Error(
            `The ${
                resource.type.name
            } list must have an order value for each sort field. Sort fields are "${fields.join(
                ','
            )}" but sort orders are "${orders.join(',')}"`
        );
      }

      const multiSort = fields.map((field, index) =>
          makeSort(field, orders[index])
      );
      result['order_by'] = multiSort;
    } else {
      result['order_by'] = makeSort(field, order);
    }
  }

  if (distinct_on) {
    result['distinct_on'] = distinct_on;
  }

  return result;
};

const makeSort = (field, sort) => {
  const [fieldName, operation] = field.split(SPLIT_OPERATION);
  const fieldSort = operation ? `${sort}_${operation}` : sort;
  return set({}, fieldName, fieldSort.toLowerCase());
};

/**
 * if the field contains a SPLIT_OPERATION, it means it's column ordering option.
 *
 * @example
 * ```
 * makeSort('title', 'ASC') => { title: 'asc' }
 * ```
 * @example
 * ```
 * makeSort('title@nulls_last', 'ASC') => { title: 'asc_nulls_last' }
 * ```
 * @example
 * ```
 * makeSort('title@nulls_first', 'ASC') => { title: 'asc_nulls_first' }
 * ```
 *
 */
const typeAwareKeyValueReducer =
  (introspectionResults, resource, params) => (acc, key) => {
    const type = introspectionResults.types.find(
      (t) => t.name === resource.type.name
    );
    const field = type.fields.find((t) => t.name === key);
    const value =
      field &&
      field.type &&
      field.type.name === "date" &&
      params.data[key] === ""
        ? null
        : params.data[key];
    return resource.type.fields.some((f) => f.name === key)
      ? {
          ...acc,
          [key]: value,
        }
      : acc;
  };

const buildUpdateVariables =
  (introspectionResults) =>
  (resource, aorFetchType, params, queryType, usePermittedFields) => {
    const reducer = typeAwareKeyValueReducer(
      introspectionResults,
      resource,
      params
    );
    let permitted_fields = null;
    const resource_name = resource.type.name;
    if (resource_name) {
      let inputType = introspectionResults.types.find(
        (obj) => obj.name === `${resource_name}_set_input`
      );
      if (inputType) {
        let inputTypeFields = inputType.inputFields;
        if (inputTypeFields) {
          permitted_fields = inputTypeFields.map((obj) => obj.name);
        }
      }
    }
    const results = Object.keys(params.data).reduce((acc, key) => {
      // If hasura permissions do not allow a field to be updated like (id),
      // we are not allowed to put it inside the variables
      // RA passes the whole previous Object here
      // https://github.com/marmelab/react-admin/issues/2414#issuecomment-428945402

      // Fetch permitted fields from *_set_input INPUT_OBJECT and filter out any key
      // not present inside it
      if (
        usePermittedFields &&
        permitted_fields &&
        !permitted_fields.includes(key)
      )
        return acc;

      if (
        params.previousData &&
        params.data[key] === params.previousData[key]
      ) {
        return acc;
      }
      return reducer(acc, key);
    }, {});
    return results;
  };

const typeAwareKeyValueManyReducer =
  (introspectionResults, resource) => (acc, key, dataItem) => {
    const type = introspectionResults.types.find(
      (t) => t.name === resource.type.name
    );
    const field = type.fields.find((t) => t.name === key);
    const value =
      field &&
      field.type &&
      field.type.name === "date" &&
      dataItem[key] === ""
        ? null
        : dataItem[key];
    return resource.type.fields.some((f) => f.name === key)
      ? {
        ...acc,
        [key]: value,
      }
      : acc;
  };

const buildUpdateManyVariables =
  (introspectionResults) =>
    (resource, aorFetchType, params, queryType, usePermittedFields) => {
      const reducer = typeAwareKeyValueManyReducer(
        introspectionResults,
        resource
      );
      let permitted_fields = null;
      const resource_name = resource.type.name;
      if (resource_name) {
        let inputType = introspectionResults.types.find(
          (obj) => obj.name === `${resource_name}_set_input`
        );
        if (inputType) {
          let inputTypeFields = inputType.inputFields;
          if (inputTypeFields) {
            permitted_fields = inputTypeFields.map((obj) => obj.name);
          }
        }
      }

      const results = params.data.map((value) => {
        return Object.keys(value).reduce((acc, key) => {
          // If hasura permissions do not allow a field to be updated like (id),
          // we are not allowed to put it inside the variables
          // RA passes the whole previous Object here
          // https://github.com/marmelab/react-admin/issues/2414#issuecomment-428945402

          // Fetch permitted fields from *_set_input INPUT_OBJECT and filter out any key
          // not present inside it
          if (
            usePermittedFields &&
            permitted_fields &&
            !permitted_fields.includes(key)
          )
            return acc;

          return reducer(acc, key, value);
        }, {});
      })

      return {
        updates: results.map((item) => {
            return {
                where: {
                  id: {
                      _eq: item.id,
                  },
                },
                _set: item,
            };
        }),
      }
    };


const buildCreateVariables =
  (introspectionResults) => (resource, aorFetchType, params, queryType) => {
    const reducer = typeAwareKeyValueReducer(
      introspectionResults,
      resource,
      params
    );
    return Object.keys(params.data).reduce(reducer, {});
  };

const makeNestedTarget = (target, id) =>
  // This simple example should make clear what this function does
  // makeNestedTarget("a.b", 42)
  // => { a: { b: { _eq: 42 } } }
  target
    .split(".")
    .reverse()
    .reduce(
      (acc, key) => ({
        [key]: acc,
      }),
      { _eq: id }
    );

export const customBuildVariables =
  (introspectionResults) => (resource, aorFetchType, params, queryType) => {
    switch (aorFetchType) {
      case GET_LIST:
        return buildGetListVariables(introspectionResults)(
          resource,
          aorFetchType,
          params,
          queryType
        );
      case GET_MANY_REFERENCE: {
        var built = buildGetListVariables(introspectionResults)(
          resource,
          aorFetchType,
          params,
          queryType
        );
        if (params.filter) {
          return {
            ...built,
            where: {
              _and: [
                ...built["where"]["_and"],
                makeNestedTarget(params.target, params.id),
              ],
            },
          };
        }
        return {
          ...built,
          where: makeNestedTarget(params.target, params.id),
        };
      }
      case GET_MANY:
      case DELETE_MANY:
        return {
          where: { id: { _in: params.ids } },
        };

      case GET_ONE:
        return {
          where: { id: { _eq: params.id } },
          limit: 1,
        };

      case DELETE:
        if (
          queryType.name === "delete_sftplocation" ||
          queryType.name === "delete_ftplocation" ||
          queryType.name === "delete_fasplocation" ||
          queryType.name === "delete_locallocation"
        ) {
          const where =
            queryType.name === "delete_sftplocation"
              ? "sftp"
              : queryType.name === "delete_ftplocation"
              ? "ftp"
              : queryType.name === "delete_fasplocation"
              ? "fasp"
              : queryType.name === "delete_locallocation" && "local";
          return {
            where: {
              [`${where}_location_id`]: {
                _eq: params.previousData[`${where}_location_id`],
              },
            },
          };
        } else {
          return {
            where: { id: { _eq: params.id } },
          };
        }
      case CREATE:
        if (queryType.name === "insert_destinationrecipient") {
          if (Array.isArray(params.data)) {
            return {
              objects: params.data,
            };
          } else {
            return {
              objects: {
                ...params.data,
              },
            };
          }
        }
        if (queryType.name === "insert_recipient") {
          return {
            objects: {
              ...params.data,
              name:`${params.data.first_name} ${params.data.last_name}`,
              destinations: {
                data: [...(params.data.destinations || [])]
              }
            }
          }
        }
        if (queryType.name === "insert_format") {
          return {
            objects: {
              ...params.data,
              discriminator: "format",
            },
          };
        }
        if (queryType.name === "insert_location") {
          return buildMutationLocation(params);
        }
        if (
          queryType.name === "insert_location" ||
          queryType.name === "insert_ftplocation" ||
          queryType.name === "insert_sftplocation" ||
          queryType.name === "insert_fasplocation" ||
          queryType.name === "insert_locallocation"
        ) {
          return buildCreateLocation(params);
        }
        if (queryType.name === "insert_transcoder") {
          if (
            params.data.type === "vantage" ||
            params.data.type === "elemental"
          ) {
            return {
              objects: {
                name: params.data.name,
                type: params.data.type,
                transcoderconfig: {
                  data: {
                    host: params.data.transcoderconfig.host,
                    port: params.data.transcoderconfig.port,
                  },
                },
              },
            };
          }
        }
        if (queryType.name === "insert_pathmapping") {
          return {
            objects: {
              src: params.data.src,
              dest: params.data.dest,
              config_id: params.data.config_id,
            },
          };
        }
        if (queryType.name === "insert_brsjob") {
          return {
            objects: {
              ...buildCreateVariables(introspectionResults)(
                resource,
                aorFetchType,
                params,
                queryType
              ),
              destinations: {
                data: params.data.destinations
              },
              trafficinstructions: {
                data: params.data.trafficinstructions
              }
            }
          }
        }
        if (queryType.name === "insert_brsjobdestination") {
          if (Array.isArray(params.data)) {
            return {
              objects: params.data,
            };
          } else {
            return {
              objects: {
                ...params.data,
              },
            };
          }
        }
        return {
          objects: buildCreateVariables(introspectionResults)(
            resource,
            aorFetchType,
            params,
            queryType
          ),
        };
      case UPDATE:
        if (
          queryType.name === "update_sftplocation" ||
          queryType.name === "update_ftplocation" ||
          queryType.name === "update_fasplocation" ||
          queryType.name === "update_locallocation"
        ) {
          const where =
            queryType.name === "update_sftplocation"
              ? "sftp"
              : queryType.name === "update_ftplocation"
              ? "ftp"
              : queryType.name === "update_fasplocation"
              ? "fasp"
              : queryType.name === "update_locallocation" && "local";

          return {
            _set: buildUpdateVariables(introspectionResults)(
              resource,
              aorFetchType,
              { ...params, data: { ...params.data } },
              queryType,
              false
            ),
            where: {
              [`${where}_location_id`]: {
                _eq: params.data[`${where}_location_id`],
              },
            },
          };
        }

        if (queryType.name === "update_transcoderconfig") {
          return {
            _set: buildUpdateVariables(introspectionResults)(
              resource,
              aorFetchType,
              { ...params, data: { ...params.data } },
              queryType,
              false
            ),
            where: { transcoder_id: { _eq: params.data.transcoder_id } },
          };
        }
        return {
          _set: buildUpdateVariables(introspectionResults)(
            resource,
            aorFetchType,
            params,
            queryType,
            true
          ),
          where: { id: { _eq: params.id } },
        };

      case UPDATE_MANY:
        return buildUpdateManyVariables(introspectionResults)(
          resource,
          aorFetchType,
          params,
          queryType
        );
      default:
        return
    }
  };
