import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput, AutocompleteArrayInput,
} from "react-admin";
import { createUpdateStyle } from "../../style";
import Typography from "@material-ui/core/Typography";
import { CustomToolbar } from "../../components/CustomToolBar";
import * as React from "react";

export const DestinationCreate = (props) => {
  const classes = createUpdateStyle();
  const transform = (data) => {
    const tags = data?.tags ? data.tags : []
    return {
      ...data,
      tags: JSON.stringify(tags).replace('[', '{').replace(']', '}'),
      xml_sidecar: data?.xml_sidecar ? data.xml_sidecar : false
    }
  };
  return (
    <div className={classes.card}>
      <a className={classes.breadcrumbLink} href="#/destination">
        {"< Destinations"}
      </a>
      <Create transform={transform} title="Add Destination" {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar {...props} />}>
          <Typography variant="h6">Add Destination</Typography>
          <TextInput
            className={classes.textField}
            helperText={false}
            source="name"
          />
          <TextInput
            className={classes.textField}
            helperText={false}
            source="call_letters"
            label="Destination ID"
          />
          <ReferenceInput
            className={classes.textField}
            helperText={false}
            source="format_id"
            reference="format"
            perPage={1000}
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput
            className={classes.textField}
            helperText={false}
            source="location_id"
            reference="location"
            perPage={1000}
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput source="name" />
          </ReferenceInput>
          <AutocompleteArrayInput source="tags" choices={[
            { id: "CABLE_REEL_SA", name: "SA Reel" },
            { id: "CABLE_REEL_1", name: "Cable Reels" },
          ]} />
          <BooleanInput
              className={classes.textField}
              label="SD"
              source="sd"
              helperText={false}
          />
          <BooleanInput
              className={classes.textField}
              label="XML Sidecar"
              source="xml_sidecar"
              helperText={false}
          />
        </SimpleForm>
      </Create>
    </div>
  );
};
