import React, { Fragment, useEffect, useState } from "react";
import {
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  ListContextProvider,
  Loading,
  Pagination,
  SelectInput,
  TextField,
  TextInput,
  useQuery,
} from "react-admin";
import { ProgressField } from "../../components/ProgressBar";
import { StatusField } from "../../components/StatusField";
import keyBy from "lodash/keyBy";
import debounce from "lodash/debounce";
import { Card } from "@material-ui/core";

const JobFilter = (props) => {
  return (
    <Filter>
      <TextInput
        label="Search"
        source="batch_id@_ilike,brs_job#request_id@_ilike,brs_job#broadcastrequests#isci@_ilike,reels#house_isci@_ilike,pods#house_isci@_ilike"
        alwaysOn
      />
      <SelectInput
        source="job_type"
        choices={[
          { id: "batch", name: "Batch" },
          { id: "reel", name: "Reel" },
          { id: "sareel", name: "SA Reel" },
          { id: "brs", name: "BRS" },
          { id: "nationalpod", name: "National POD" },
          { id: "localpod", name: "Local POD" },
        ]}
        alwaysOn
      />
    </Filter>
  );
};

const buildFilterQuery = (filterValues, page, perPage, currentSort) => {
  return {
    type: "getList",
    resource: "job",
    payload: {
      pagination: { page, perPage },
      sort: currentSort,
      filter: { ...filterValues },
    },
  };
};

export const JobList = (props) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [currentSort, setSort] = useState({ field: "updated", order: "DESC" });
  const [eventSource, setEventSource] = useState(null);
  const [filterValues, setFilterValues] = useState({});
  const [jobIds, setJobIds] = useState([]);
  const [jobData, setJobData] = useState([]);
  const { data, total } = useQuery(
    buildFilterQuery(filterValues, page, perPage, currentSort),
    {
      onFailure: (error) => {
        console.error(error);
      },
    },
  );

  useEffect(() => {
    if (data) {
      setJobData(keyBy(data, "id"));
      setJobIds(data.map(({ id }) => id));
    }
  }, [data]);

  const debouncedHandleFilterChange = debounce((value) => {
    setFilterValues(value);
  }, 700);

  return jobData ? (
    <ListContextProvider
      value={{
        data: jobData,
        ids: jobIds,
        total,
        filterValues: filterValues,
        setFilters: debouncedHandleFilterChange,
        page,
        perPage,
        setPage,
        setPerPage,
        setSort: (field, order) => setSort({ field, order }),
        currentSort,
        resource: props.resource,
        selectedIds: [],
      }}
    >
      <JobFilter />
      <Card>
        <Datagrid rowClick="show">
          <FunctionField
            label="ID"
            render={(record) =>
              record.job_type === "brs"
                ? record.brs_job?.request_id
                : record.batch_id
            }
          />
          <FunctionField
            label="Job Type"
            style={{ textTransform: "capitalize" }}
            render={(record) =>
              record.job_type === "brs"
                ? "BRS"
                : record.job_type === "nationalpod"
                  ? "National POD"
                  : record.job_type === "localpod"
                    ? "Local POD"
                    : record.job_type === "sareel"
                      ? "SA Reel"
                      : record.job_type
            }
          />
          <DateField source="created" label="Date Created" showTime />
          <DateField source="updated" label="Date Updated" showTime />
          <StatusField label="Status" source="status"></StatusField>
          <ProgressField source="progress" type="job" />
        </Datagrid>
        <Pagination rowsPerPageOptions={[25, 50, 100, 250, 500]} />
      </Card>
    </ListContextProvider>
  ) : (
    <Loading />
  );
};
