import { useRecordContext } from "react-admin";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PropTypes from "prop-types";

export const TextFieldWithIcon = (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  const isCheckMark = record?.step_status !== "PREFLIGHT_IN_PROGRESS" && record?.step_status !== "PREFLIGHT_ERROR"
  return (
    <div style={{ display: "flex", alignItems: "flex-start" }}>
      {record?.[source] && (
        <span
          style={{ color: isCheckMark ? "#90caf9" : "#8c8c8c" }}
        >
          {record?.[source]}
        </span>
      )}
      {isCheckMark && record?.[source] && (
        <CheckCircleIcon
          style={{ fontSize: "18px", marginLeft: "4px" }}
          color={"primary"}
        />
      )}
    </div>
  );
};

TextFieldWithIcon.propTypes = {
  source: PropTypes.string,
}
