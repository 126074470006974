import {
  Edit, Loading, required, SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import { createUpdateStyle } from "../../style";
import {EmailEditor, EmailEditorToolbar} from "../../components";
import * as React from "react";
import {useEffect, useState} from "react";
import {getEmailTemplate} from "../../helpers";

export const EmailTemplateEdit = (props) => {
  const [body,setBody] =useState(null)
  useEffect(async () => {
    const emailTemplateBody = await getEmailTemplate(props.id)
    setBody(emailTemplateBody.body)
  },[])
  const classes = createUpdateStyle();

  return body ? (
      <div style={{display:'inline-flex'}}>
      <div style={{ width: "70%", height: "45%", marginTop: "24px" }}>
        <a className={classes.breadcrumbLink} href="#/emailtemplate">
          {"< Email Template"}
        </a>
        <Edit title="Edit Email Template" {...props} actions={false}>
          <SimpleForm toolbar={<EmailEditorToolbar isEdit={true} {...props} />}>
            <Typography variant="h6">Edit Email Template</Typography>
            <br/>
            <SelectInput source="type" label="Template Type" choices={[
              { id: 'BRS', name: 'BRS' },
            ]} />
            <TextInput
                className={classes.textField}
                helperText={false}
                source="subject"
                validate={required()}
            />
            <br/>
            <EmailEditor source="body" body={body} isEdit={true}/>
          </SimpleForm>
        </Edit>
      </div>
  <div style={{marginTop:"80px",width: "25%",marginLeft:"48px"}}>
    <Typography variant="h6">Available Variables</Typography>
    <br/>
    <Typography variant="h8">Subject</Typography>:
    <List>
      {
        ["{{ti_name}}}"].map((text) => <ListItem><ListItemText primary={text}/></ListItem>)
      }
    </List>
    <br/>
  </div>
      </div>
  ) : <Loading/>;
};
