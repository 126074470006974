import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import * as React from "react";
import {BroadcastListInput} from "./";

export const BroadcastRequestDialog = (props) => {
  const {handleDialog, ...rest} = props;
  return (
      <Dialog open={true} onClose={handleDialog} fullWidth>
        <DialogTitle>Bulk Add ISCIs</DialogTitle>
        <DialogContent>
          <Card>
            <CardContent>
              <BroadcastListInput
                  handleDialog={handleDialog}
                  broadcastRequest={props.broadcastRequests}
                  style={{width: '45%'}}
                  source="broadcast_list"
                  resource="broadcastrequest"
                  {...rest}
              />
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
  );
}
