import { Datagrid, List, TextField, FunctionField } from "react-admin";
import { FilterList, ListActions, CustomPagination } from "../../components";

export const RecipientList = (props) => {
  return (
    <List
      {...props}
      perPage={100}
      actions={<ListActions />}
      pagination={<CustomPagination rowsPerPageOptions={[25, 50, 100, 250, 500]}/>}
      filters={<FilterList source="destinations#destination#call_letters@_ilike,email"/>}
    >
      <Datagrid rowClick="edit">
        <TextField source="email" />
        <FunctionField source="destinations" render={record => record.destinations && record.destinations.length} />
      </Datagrid>
    </List>
  );
};
