import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import Typography from "@material-ui/core/Typography";
import { CustomToolbar } from "../../components/CustomToolBar";
import { createUpdateStyle } from "../../style";

export const FormatEdit = (props) => {
  const classes = createUpdateStyle();
  return (
    <div style={{ width: "42%", height: "50%", marginTop: "24px" }}>
      <a className={classes.breadcrumbLink} href="#/format">
        {"< Formats"}
      </a>
      <Edit title="Edit Format" {...props} actions={false}>
        <SimpleForm toolbar={<CustomToolbar isEdit={true} {...props} />}>
          <Typography variant="h6">Edit Format</Typography>
          <TextInput
            className={classes.textField}
            helperText={false}
            source="name"
          />
          <TextInput
            className={classes.textField}
            helperText={false}
            source="ext"
          />
          <ReferenceInput
            className={classes.textField}
            helperText={false}
            source="transcoder_id"
            reference="transcoder"
            label="Transcoder"
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput
            source="output_dir"
            label="Ouput Folder"
            className={classes.textField}
            helperText={false}
          />
          <TextInput
            source="workflow_id"
            label="Workflow ID"
            className={classes.textField}
            helperText={false}
          />
        </SimpleForm>
      </Edit>
    </div>
  );
};
