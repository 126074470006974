import * as React from "react";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import pick from "lodash/pick";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Button,
  Edit,
  email,
  ReferenceInput, required,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useCreate,
  useDeleteMany,
  useNotify,
  useRecordContext,
  useRedirect,
  useUpdate,
  useUpdateMany,
} from "react-admin";
import {CustomToolbar as BaseCustomToolbar} from "../../components/CustomToolBar";
import {createUpdateStyle} from "../../style";

const CustomToolbar = (props) => {
  const [update] = useUpdate();
  const [create] = useCreate();
  const [updateMany] = useUpdateMany();
  const [deleteMany] = useDeleteMany();
  const redirect = useRedirect();
  const notify = useNotify();
  const record = useRecordContext();

  const onSave = async (data) => {
    const newData = pick(data, "id", "first_name", "last_name", "email");
    const previousData = pick(record, "id", "first_name", "last_name", "email");

    const destinationData = get(data, "destinations").filter(destination => !!destination)

    const existingDestinations = destinationData.filter(destination => !!destination.id)
    const existingDestinationsIds = existingDestinations.map((destination) => destination.id);
    const destinationsToUpdate = existingDestinations.filter((destination) => {
      const previousDestination = record.destinations.find((d) => d.id === destination.id);
      return !isEqual(previousDestination, destination);
    });

    const destinationIdsToDelete = record.destinations.map(destination => destination.id).filter(id => !existingDestinationsIds.includes(id))

    const newDestinations = destinationData.filter(destination => !destination.id).map((destination) => ({
      recipient_id: props.id,
      ...destination,
    }));

    if (destinationIdsToDelete.length > 0) {
      // Delete destinations
      try {
        await deleteMany(
            "destinationrecipient",
            destinationIdsToDelete,
            {onSuccess: () => redirect(false)}
        );
      } catch (error) {
        notify(`${error}`, "warning");
      }
    }

    if (newDestinations.length > 0) {
      // Create new destinations
      try {
        await create(
            "destinationrecipient",
            newDestinations,
            {onSuccess: () => redirect(false)}
        );
      } catch (error) {
        notify(`${error}`, "warning");
      }
    }

    if (existingDestinationsIds.length > 0 && destinationsToUpdate.length > 0) {
      // Update existing destinations
      try {
        await updateMany(
            "destinationrecipient",
            existingDestinationsIds,
            existingDestinations,
            {onSuccess: () => redirect(false)}
        );
      } catch (error) {
        notify(`${error}`, "warning");
      }
    }

    // Update recipient
    if (!isEqual(previousData, newData)) {
      try {
        await update(
            "recipient",
            props.id,
            newData,
            previousData,
            {onSuccess: () => redirect(false)}
        );
      } catch (error) {
        notify(`${error}`, "warning");
      }
    }

    notify("Element updated", "info");
    redirect(`/${props.resource}`);
  }

  return (
      <BaseCustomToolbar {...props} saveButtonExtraProps={{onSave}}/>
  );
};

export const RecipientEdit = (props) => {
  const validateEmail = email();
  const classes = createUpdateStyle();
  return (
      <div className={classes.card}>
        <a className={classes.breadcrumbLink} href="#/recipient">
          {"< Recipients"}
        </a>{" "}
        <Edit title="Edit Recipient" {...props} actions={false}>
          <SimpleForm toolbar={<CustomToolbar isEdit={true} {...props} />}>
            <Typography variant="h6">Edit Recipient</Typography>
            <TextInput
                className={classes.textField}
                helperText={false}
                source="first_name"
                validate={required()}
            />
            <TextInput
                className={classes.textField}
                helperText={false}
                source="last_name"
                validate={required()}
            />
            <TextInput
                className={classes.textField}
                helperText={false}
                source="email"
                validate={[required(), validateEmail]}
                type="email"
            />
            <ArrayInput source="destinations">
              <SimpleFormIterator
                  disableReordering
                  addButton={
                    <Button label="ra.action.add" style={{color: "#3CE7EA"}}>
                      <AddIcon/>
                    </Button>
                  }
                  removeButton={
                    <Button label="ra.action.remove" style={{color: "#e57373"}}>
                      <CloseIcon/>
                    </Button>
                  }
              >
                <ReferenceInput
                    className={classes.textField}
                    helperText={false}
                    source="destination_id"
                    reference="destination"
                    sort={{field: "name", order: "ASC"}}
                    label="Destination"
                    validate={required()}
                    perPage={10000}
                    filterToQuery={searchText => ({name: searchText})}
                >
                  <AutocompleteInput />
                </ReferenceInput>
                <BooleanInput className={classes.switch} source="traffic_instructions" helperText={false}
                              label="Traffic Instructions"/>
                <BooleanInput className={classes.switch} source="media_delivery" helperText={false} label="Media"/>
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleForm>
        </Edit>
      </div>
  );
};
