// import React, { useEffect, useState } from "react";
// import { useLogin, useNotify } from "react-admin";
// import { loginStyles } from "../../style";
// import {
//   Button,
//   Card,
//   CardContent,
//   Container,
//   Typography,
// } from "@material-ui/core";
// import axios from "axios";
// import { getRole } from "../../authProvider/authProvider";

// let SSO_ENV =
//   window.NODE_ENV === "production"
//     ? "platform"
//     : window.NODE_ENV === "development"
//     ? "cd"
//     : window.NODE_ENV === "stage" && "stg";

// const getToken = async (sso) => {
//   const res = await axios
//     .post(
//       `https://${SSO_ENV}.cadent.tv/ulpapi/api/v1/Security/sso/complete`,
//       null,
//       {
//         headers: {
//           application_id: process.env.REACT_APP_APPLICATION_ID,
//           entity_id: process.env.REACT_APP_ENTITY_ID,
//           Authorization: `Bearer ${sso}`,
//         },
//       }
//     )
//     .catch((e) => console.log(e));
//   if (res && res.data.success) {
//     const role = getRole(res.data.result.authorizations[0].roles);
//     return {
//       ssoComplete: res.data.success,
//       roles: role,
//       expires: res.data.result.expires,
//     };
//   }
// };

// export const Login = () => {
//   const classes = loginStyles();
//   const login = useLogin();
//   const notify = useNotify();
//   const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     //CHECK URL FOR SEARCH::GET CODE OR WHATEVER IT WILL BE CALLED
//     const searchParams = new URLSearchParams(window.location.search).get("sso");
//     if (searchParams && window.CADENT_SSO_V2 === "false") {
//       setLoading(true);
//       // - TOKEN FROM OKTA IS PRESENT
//       // - WORK FOR CALLING getSSOComplete CAN GO HERE
//       // - ONCE getSSOComplete RETURNS WITH A VALID BEARER TOKEN CALL LOGIN/WHERE THE TOKEN WILL BE SET
//       getToken(searchParams).then((data) => {
//         setLoading(false);
//         window.history.replaceState(null, null, window.location.pathname);
//         if (data.ssoComplete === true) {
//           login({ ...data, url: window }).catch(() => {
//             notify("Something went wrong");
//           });
//         }
//       });
//     }
//   }, [login, notify]);

//   function handleLogin() {
//     if (process.env.NODE_ENV === "development") {
//       login({
//         ssoComplete: true,
//         roles: ["dh-admin"],
//         expires: "2024-09-10T22:42:03.9111037Z",
//       }).catch(() => notify("Something went wrong"));
//     }else{
//       let authUrl = `https://${SSO_ENV}.cadent.tv/ulp/?return_to=${window.location.origin}`;
//       if (window.CADENT_SSO_V2 === "true") {
//         authUrl = `https://${SSO_ENV}.cadent.tv/ulp/?ext_app_logout=false&return_to=${window.location.host}`
//       }
//       window.location.replace(authUrl);
//     }

//   }

//   return (
//     <section className={classes.container}>
//       <Container maxWidth="lg">
//         <Container component="main" maxWidth="xs">
//           <Card className={classes.card}>
//             <Typography className={classes.logo}>CADENT</Typography>
//             <CardContent className={classes.content}>
//               <Button
//                 onClick={() => handleLogin()}
//                 disabled={loading}
//                 className={classes.button}
//               >
//                 SIGN IN CADENT
//               </Button>
//             </CardContent>
//           </Card>
//         </Container>
//       </Container>
//     </section>
//   );
// };

import React, { useState, useEffect } from "react";
import {
  useLogin,
  useNotify,
  Notification,
  TextInput,
  SimpleForm,
} from "react-admin";
import { Button, CircularProgress } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import cadentLogo from "../../assets/cadentLogo.svg";
import { loginStyles } from "../../style";

export const Login = () => {
  const classes = loginStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const login = useLogin();
  const notify = useNotify();

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      login({ username, password });
      // Login successful, React-admin will handle redirection
    } catch (error) {
      notify("Invalid username or password");
    }
    setLoading(false);
  };
  if (loading) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  return (
    <div className={classes.loginPageWrapper}>
      <div>
        <img
          className={classes.cadentLogo}
          src={cadentLogo}
          alt="Cadent Logo"
        />
      </div>
      <SimpleForm onSubmit={handleLogin} toolbar={false}>
        <div className={classes.loginForm}>
          <TextInput
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            source="username"
            //fullWidth
            required
            className={classes.loginField}
          />

          <TextInput
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            source="password"
            //fullWidth
            required
            className={classes.loginField}
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            className={classes.loginButton}
          >
            {loading ? <CircularProgress size={24} /> : "Login"}
          </Button>
        </div>
      </SimpleForm>
      <Notification />
    </div>
  );
};
