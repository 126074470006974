import {
  Datagrid,
  TextField,
  TextInput,
  Filter,
  FunctionField,
  useQuery, Pagination, Loading, ListContextProvider, ArrayField, DateField,
} from "react-admin";
import * as React from "react";
import {Card, makeStyles} from "@material-ui/core";
import {TextFieldWithIcon} from "../../components/TextFieldWithIcon";
import {useEffect, useState} from "react";
import debounce from "lodash/debounce";
import keyBy from "lodash/keyBy";
import {StatusField, StepperField, StepperFieldJobAr} from "../../components";


const useStyles = makeStyles((theme) => ({
  label: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
  },
  paddingLeft: {
    paddingLeft: "16px",
  },
  table: {
    width: "38%",
    marginRight: "auto",
    marginLeft:"32px"
  },
  headerCell: {
    borderBottom: "0px"
  },
  rowCell: {
    borderBottom: "0px",
  },
}));

const buildFilterQuery = (resource,filterValues, page, perPage, currentSort) => {
  return {
    type: "getList",
    resource,
    payload: {
      pagination: {page, perPage},
      sort: currentSort,
      filter: {...filterValues}
    }
  }
};

const DestinationList = (props) => {
  const {data, total} = useQuery(buildFilterQuery('deliveryrequest',{[`${props.resource}_id`]:props.record.id}, 1, 1000, {field: "created", order: "DESC"}),
      {
        onFailure: (error) => {
          console.error(error);
        }
      });
  const classes = useStyles();
  return data?.length > 0 ?
      <Datagrid  data={keyBy(data, 'id')}
                 ids={data.map(({id}) => id)}
                 classes={{table: classes.table, rowCell: classes.rowCell, headerCell: classes.headerCell}}>
        <TextField source="call_letters" label="Destination"/>
        <FunctionField label="Date/Time delivered" render={(r)=>r.status === "COMPLETE" || r.status === "ERROR" ? <DateField source="transfergroup.updated"  showTime/> : null}/>
        <StatusField source="status"/>
      </Datagrid>
      : <div style={{marginLeft:48}}> <h4>No destinations for  {props.record.house_isci}</h4></div>
}
const ArFilter = (props) => {
  return (
      <Filter {...props}>
        <TextInput label="Search" source="reel_id,pod_id" alwaysOn/>
      </Filter>
  );
};


export const ArShow = (props) => {
  const classes = useStyles();
  const [reelData, setReelData] = useState([])
  const [reelIds, setReelIds] = useState([])
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [currentSort, setSort] = useState({field: "created", order: "DESC"});
  const [eventSource, setEventSource] = useState(null)
  const [filterValues, setFilterValues] = useState({[`${props.resource}_id`]: '',"job_id":props.id})
  const {data, total} = useQuery(buildFilterQuery(props.resource,filterValues, page, perPage, currentSort),
      {
        onFailure: (error) => {
          console.error(error);
        }
      });

  // useEffect(() => {
  //   if (eventSource) {
  //     eventSource.close()
  //   }
  //   const evtSource = new EventSource(`${window.REACT_APP_API_URL}/subscribe/${props.resource}/${props.id}/details?sort_order=${currentSort.order}&sort_field=${currentSort.field}&per_page=${perPage}&page=${page}`)
  //   setEventSource(evtSource)
  //   evtSource.addEventListener("new_event", function (event) {
  //     const newData = JSON.parse(event.data)
  //     setReelData(keyBy(newData, 'id'))
  //     setReelIds(newData.map(({id}) => id))
  //   });
  //   evtSource.addEventListener("end_event", function (event) {
  //     evtSource.close();
  //   });
  //
  //   return () => {
  //     evtSource.close();
  //   };
  // }, [currentSort, perPage, page]);

  useEffect(() => {
    if (data) {
      setReelData(keyBy(data, 'id'))
      setReelIds(data.map(({id}) => id))
    }
  }, [data])

  const debouncedHandleFilterChange = debounce((value) => {
    setFilterValues(value);
  }, 700);


  return reelData ? (
      <ListContextProvider value={{
        data: reelData,
        ids: reelIds,
        total,
        filterValues: filterValues,
        setFilters: debouncedHandleFilterChange,
        page,
        perPage,
        setPage,
        setPerPage,
        setSort: (field, order) => setSort({field, order}),
        currentSort,
        resource: props.resource,
        selectedIds: [],
      }}>
        <ArFilter/>
        <Card>
          <Datagrid resource={props.resource}  expand={<DestinationList/>}>
            <TextFieldWithIcon source="house_isci" label="ISCI"/>
            <TextField source={`${props.resource}_id`} label={`${props.resource} id`.toUpperCase()}/>
            <TextField source="adv_name" label={"Advertiser"}/>
            <TextField source="length" label={"Duration"}/>
            <FunctionField
                source="master_iscis"
                render={(record) => record.master_iscis && record.master_iscis.map(isci => {
                  if (!record?.invalid_iscis) {
                    return <span style={{fontSize: "14px", marginLeft: "4px", color: "#90caf9"}}>{isci}</span>
                  } else if (record?.invalid_iscis.includes(isci)) {
                    return <span style={{fontSize: "14px", marginLeft: "4px", color: "#8c8c8c"}}>{isci}</span>
                  } else {
                    return <span style={{fontSize: "14px", margin: "8px", color: "#90caf9"}}>{isci}</span>
                  }
                }).reduce((prev, curr) => [prev, ' +', curr])}
                label={"Master ISCI(s)"}
            />
            {props.resource === "reel" ? <StepperField
                    label={
                      <div className={classes.label}>
                        <span>Preflight</span>
                        <span className={classes.paddingLeft}>Transcodes</span>
                        <span className={classes.paddingLeft}>Transfers</span>
                        <span>Notifications</span>
                      </div>
                    }
                /> :
                <StepperFieldJobAr
                    label={
                      <div className={classes.label}>
                        <span>Preflight</span>
                        <span className={classes.paddingLeft}>Transcodes</span>
                        <span className={classes.paddingLeft}>Transfers</span>
                        <span>Notifications</span>
                      </div>
                    }
                ></StepperFieldJobAr>
            }
          </Datagrid>
          <Pagination
              rowsPerPageOptions={[25, 50, 100, 250, 500]}
          />
        </Card>
      </ListContextProvider>
  ) : (
      <Loading/>
  );
};
