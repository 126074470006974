import { Datagrid, List, TextField } from "react-admin";
import { CustomPagination } from "../../components/CustomPagination";
import { FilterList, ListActions } from "../../components/index";

export const InventorySourceList = (props) => {
  return (
    <List
      {...props}
      perPage={100}
      actions={<ListActions label="Inventory Source" />}
      pagination={
        <CustomPagination rowsPerPageOptions={[25, 50, 100, 250, 500]} />
      }
      filters={<FilterList />}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" label="Inventory Source ID" />
        <TextField source="name" label="Inventory Source Name" />
      </Datagrid>
    </List>
  );
};
