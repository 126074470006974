import * as React from "react";
import {
  ChipField,
  Create, Datagrid, FileField, FileInput, FunctionField, Loading,
  SimpleForm, TextField,
  useNotify,
  useRedirect
} from "react-admin";
import {CustomToolbar as BaseCustomToolbar} from "../../components/CustomToolBar";
import {createUpdateStyle, fileInputStyle} from "../../style";
import { useState} from "react";
import {FormSpy } from "react-final-form";
import {triggerPodWorkflow, triggerValidatePodFile } from "../../helpers";
import keyBy from "lodash/keyBy";

const PodList = (props) => {
  return (
        <Datagrid  data={keyBy(props.pods, "id")}
        ids={props.pods.map(({id}) => id)}
        currentSort={{field: "updated", order: "DESC"}}>
          <TextField source="isci" label="ISCI"/>
          <TextField source="adv_name" label={"Advertiser"}/>
          <TextField source="length" label={"Duration"}/>
          <FunctionField label="Master ISCIs"
                         render={r => r.original_iscis && r.original_iscis.map(isci => <ChipField
                             key={isci} record={{isci}} source="isci"/>)}/>
          <FunctionField label="SBTs"
                         render={r => r.sbt && r.sbt.map(sbt => <ChipField
                             key={sbt} record={{sbt}} source="sbt"/>)}/>        </Datagrid>
  );
};

const CustomToolbar = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const {formValues,isValid} = props
  const onSave = async (data) => {
    if(isValid){
      const fileName = formValues.files.rawFile.name.replace(".xlsx","");
      await triggerPodWorkflow(fileName,props.pods)
      notify("Pod File Upload Successfully", "info");
      notify("Submitting Pod Job", "INFO");
      redirect('/')
    }else{
      props.setLoading(true)
      const pods = await triggerValidatePodFile(formValues.files)
      if (pods[0] && pods[0].includes("Error")) {
        notify(`${pods[0]}`, "warning");
        props.setIsValid(false)
        props.setLoading(false)
        return
      }
      notify(`Validating Pod File, Please Wait...`, "info");
      props.setIsValid(true)
      const newPods = [...pods.pods.map((pod,index)=>{return{"id":index,...pod}})]
      props.setPods(newPods)

    }
  }
  return (
      <BaseCustomToolbar label={isValid ? "SUBMIT JOB" : "SAVE"}{...props}
                         isDisabled={!formValues?.files?.rawFile}
                         saveButtonExtraProps={{...props.saveButtonExtraProps || {}, onSave}}/>
  )
};


export const PodCreate = (props) => {
  const classes = createUpdateStyle();
  const fileInputClass = fileInputStyle()
  const [isLoading, setIsLoading] = useState(false)
  const [formValues, setFormValues] = useState()
  const [pods, setPods] = useState(null)
  const [isValid, setIsValid] = useState(false)

  return (
      <div>
        <a className={classes.breadcrumbLink} href="#/ar">
          {"< Ar Jobs"}
        </a>
        <Create title="Add Pod Job" resource="ar" basePath={"/pod"} {...props}>
          <SimpleForm
              redirect="list"
              toolbar={<CustomToolbar {...props} setIsValid={(isValid) => setIsValid(isValid)} pods={pods} setPods={pods=>setPods((old_pods)=>pods)} isValid={isValid}
                                      formValues={formValues}
                                      setLoading={(loading) => setIsLoading(loading)}/>}
          >
            {isValid && pods ? <PodList pods={pods}/> : <div style={{width: "100%", height: "65vh"}}>
              {!isLoading ? <FileInput className={fileInputClass.fileInput}
                                       source="files" label="Pod File" accept=".xlsx, .xls"
                                       placeholder={<p>Drop your POD file here</p>}>
                <FileField source="src" title="title" style={{color: "#FFF"}}/>
              </FileInput> : <Loading style={{height: 80}}/>}
            </div>}

            <FormSpy onChange={state => setFormValues(state.values)}/>
          </SimpleForm>
        </Create>
      </div>
  );
};
