const focusedColor = "#3CE7EA";

export const darkTheme = {
  palette: {
    primary: {
      main: "#90caf9",
    },
    secondary: {
      main: "#FBBA72",
    },
    type: "dark", // Switching the dark mode on is a single property value change.
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Roboto", '"Museo Sans"', "sans-serif"].join(","),
  },
  overrides: {
    RaEmpty: {
      toolbar: {
        "& .MuiButtonBase-root": {
          backgroundColor: "#3CE7EA",
          color: "#2A2A2A",
          borderRadius: "unset",
          padding: "4px 8px 4px 8px",
          textTransform: "none",
          fontSize: "14px",
          opacity: 0.9,
        },
      },
    },
    MuiStepLabel: {
      iconContainer: {
        paddingRight: "0px",
      },
    },
    RaButton: {
      label: {
        paddingLeft: 0,
      },
    },
    MuiTableCell: {
      head: {
        fontSize: "14px",
        color: "#ffffffb3",
        fontWeight: 400,
      },
    },
    MuiAppBar: {
      colorSecondary: {
        color: "#ffffffb3",
        backgroundColor: "#2A2A2A",
      },
    },
    MuiPaper: {
      elevation4: {
        boxShadow: "none",
      },
      elevation1: {
        boxShadow: "none",
      },
    },
    MuiFormLabel: {
      focused: true,
      root: {
        "&.focused": {
          color: "red",
        },
      },
    },
    MuiInputBase:{
      root: {
        '& input[type="date"]': {
          '& svg':{
            color: 'red',
            marginLeft:48
          }
          // Your styles for date input go here
        },
      },
    },
    MuiFormControl: {
      root: {
        // input label when focused
        "& label.Mui-focused": {
          color: focusedColor,
        },
        // focused color for input with variant='standard'
        "& .MuiInput-underline:after": {
          borderBottomColor: focusedColor,
        },
        // focused color for input with variant='filled'
        "& .MuiFilledInput-underline:after": {
          borderBottomColor: focusedColor,
        },
        // focused color for input with variant='outlined'
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: focusedColor,
          },
        },
      },
    },
    MuiButtonBase: {
      root: {
        "&:hover:active::after": {
          // recreate a static ripple color
          // use the currentColor to make it work both for outlined and contained buttons
          // but to dim the background without dimming the text,
          // put another element on top with a limited opacity
          content: '""',
          display: "block",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          right: 0,
          backgroundColor: "currentColor",
          opacity: 0.3,
          borderRadius: "inherit",
        },
      },
    },
    MuiSwitch: {
      switchBase: {
        color: "#3CE7EA",
      },
      colorPrimary: {
        "&.Mui-checked": {
          color: "#3CE7EA",
        },
      },
    },
  },
  props: {
    MuiButtonBase: {
      // disable ripple for perf reasons
      disableRipple: true,
    },
  },
};
