import { CreateButton, TopToolbar } from "react-admin";
import { buttonStyle } from "../../src/style";

export const ListActions = (props) => {
  const classes = buttonStyle();
  return (
    <TopToolbar>
      <CreateButton
        className={classes.button}
        label={props.label ? props.label : `New ${
          props.resource.charAt(0).toUpperCase() + props.resource.slice(1)
        }`}
        basePath={props.basePath}
      />
    </TopToolbar>
  );
};
