import { useEffect, useState } from "react";
import {
  BooleanInput,
  Button,
  Confirm,
  DeleteButton,
  Edit,
  PasswordInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useDataProvider,
  useNotify,
  useRedirect,
} from "react-admin";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import pick from "lodash/pick";
import { EditWithToolbarStyle } from "../../style";
import { useForm } from "react-final-form";

export const LocationToolbar = ({
  hasShow,
  basePath,
  hasCreate,
  hasEdit,
  hasList,
  isEdit = false,
  id,
  isTypeChanged,
  transferType,
  ...props
}) => {
  const dataProvider = useDataProvider();
  const classes = EditWithToolbarStyle();
  const redirect = useRedirect();
  const handleClick = () => {
    redirect(`/${props.resource}`);
  };
  const notify = useNotify();
  useForm().change("type", transferType);

  const handleSave = async (values) => {
    const newData = pick(values, "name", "type", "id", "bundle_files");
    const previousData = pick(props.record, "name", "type", "id", "bundle_files");
    let newValues = values;
    if (
      newValues[`${newData.type}location`] &&
      !newValues[`${newData.type}location`].hasOwnProperty("destination_path")
    ) {
      newValues[`${newData.type}location`].destination_path = "";
    }
    if (
      newValues[`${newData.type}location`] &&
      !newValues[`${newData.type}location`].hasOwnProperty("password")
    ) {
      newValues[`${newData.type}location`].password = "";
    }
    if (
      values.type === "sftp" &&
      newValues.sftplocation &&
      newValues.sftplocation.hasOwnProperty("pk")
    ) {
      newValues.sftplocation.pk = newValues.sftplocation.pk
        ? newValues.sftplocation.pk.replace(/(\r\n|\n|\r)/gm, "\n")
        : "";
    } else if (values.type === "sftp") {
      newValues.sftplocation = { ...newValues.sftplocation, pk: "" };
    }
    if (
        newValues[`${newData.type}location`] &&
        !newValues[`${newData.type}location`].hasOwnProperty("tls")
    ) {
      newValues[`${newData.type}location`].tls = false;
    }

    const createLocation = () => {
      //CREATE LOCATION FUNCTION
      dataProvider
        .create(`${newData.type}location`, {
          data: {
            ...newValues[`${newData.type}location`],
            type: newData.type,
            id,
          },
        })
        .then(({ data }) => {
          notify(`Location ${newData.type} Created`, "info");
          redirect(`/${props.resource}`);
        })
        .catch((error) => {
          notify(`${error}`, "warning");
        });
    };
    //UPDATE LOCATION
    await dataProvider
      .update("location", {
        id: id,
        data: { ...newData },
        previousData: { ...previousData },
      })
      .then(() => {
        //IF TYPE OF LOCATION CHANGE
        if (isTypeChanged) {
          //CHECK IF THE LOCATION TYPE ID EXIST
          if (
            props.record[`${props.record.type}location`] &&
            props.record[`${props.record.type}location`][
              `${props.record.type}_location_id`
            ]
          ) {
            //IF EXIST DELETE THIS RELATION LOCATION TYPE
            dataProvider
              .delete(`${previousData.type}location`, {
                id: id,
                previousData: {
                  ...props.record[`${previousData.type}location`],
                },
              })
              .catch((error) => {
                notify(`${error}`, "warning");
              })
              .then(() => {
                //AFTER DELETE THE OLD LOCATION TYPE CREATE ONE
                createLocation();
              });
          } else {
            //IF WE DONT HAVE LOCATION RELATION WITH THE TYPE, WE CREATE ONE
            createLocation();
          }
        } else {
          //IF THE TYPE DOSENT CHANGE AND WE NEED TO UPDATE THE RELATION LOCATION TYPE
          //BUT THIS FOR SOME REASON WAS DELETE IN THE DATABASE WE CHECK IF EXIST
          if (
            props.record[`${props.record.type}location`] &&
            props.record[`${props.record.type}location`][
              `${props.record.type}_location_id`
            ]
          ) {
            //IF EXIST WE UPDATE THIS ONE
            dataProvider
              .update(`${newData.type}location`, {
                id: newValues.id,
                data: { ...newValues[`${newData.type}location`] },
                previousData: {
                  ...props.record[`${props.record.type}location`],
                },
              })
              .then(({ data }) => {
                notify(`Location updated`, "info");
                redirect(`/${props.resource}`);
              })
              .catch((error) => {
                notify(`${error}`, "warning");
              });
          } else {
            //IF DOSENT EXIST WE CREATE A NEW LOCATION TYPE RELATION BASE IN THE TYPE SELECTED
            createLocation();
          }
        }
      })
      .catch((error) => {
        notify(`${error}`, "warning");
      });
  };
  return (
    <Toolbar {...props}>
      <SaveButton onSave={handleSave} className={classes.editButton} />
      <Button
        className={classes.cancelButton}
        icon={<ClearIcon />}
        label="Cancel"
        onClick={handleClick}
      />
      {isEdit && (
        <DeleteButton undoable={true} style={{ marginLeft: "auto" }} />
      )}
    </Toolbar>
  );
};

export const LocationEdit = (props) => {
  const classes = EditWithToolbarStyle();
  const [transferType, setTransferType] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isTypeChanged, setIsTypeChanged] = useState(false);
  const dataProvider = useDataProvider();
  const [error, setError] = useState();
  const [locationData, setLocationData] = useState(null);
  useEffect(() => {
    dataProvider
      .getOne("location", { id: props.id })
      .then(({ data }) => {
        setTransferType(data.type);
        setLocationData(data);
      })
      .catch((error) => {
        setError(error);
      });
  }, []);
  const handleModal = (type) => {
    if (type !== locationData.type) {
      setOpenModal(true);
      setTransferType(type);
    } else {
      setTransferType(type);
    }
  };
  return (
    <div className={classes.card}>
      <a className={classes.breadcrumbLink} href="#/location">
        {"< Locations"}
      </a>
      <Edit title="Edit Location" {...props} actions={false}>
        <SimpleForm
          toolbar={
            <LocationToolbar
              isTypeChanged={isTypeChanged}
              transferType={transferType}
              isEdit={true}
              {...props}
            />
          }
        >
          <Typography variant="h6">Edit Location</Typography>
          <TextInput
            className={classes.textField}
            source="name"
            helperText={false}
          />
          <SelectInput
            className={classes.textField}
            source="type"
            helperText={false}
            onChange={(e) => {
              handleModal(e.target.value);
            }}
            optionValue="id"
            choices={[
              { id: "local", name: "LOCAL" },
              { id: "ftp", name: "FTP" },
              { id: "sftp", name: "SFTP" },
              { id: "fasp", name: "FASP" },
            ]}
          />
          <TextInput
            helperText={false}
            className={classes.textField}
            source={
              transferType === "ftp" ||
              transferType === "sftp" ||
              transferType === "fasp"
                ? `${transferType}location.host`
                : transferType === "local" && "locallocation.url"
            }
            label={transferType === "local" ? "Url" : "Host"}
          />
          <TextInput
            className={classes.textField}
            label="Username"
            source={
              transferType === "ftp" ||
              transferType === "sftp" ||
              transferType === "fasp"
                ? `${transferType}location.user`
                : transferType === "local" && "locallocation.username"
            }
            helperText={false}
          />
          <PasswordInput
            className={classes.textField}
            label="Password"
            source={`${transferType}location.password`}
            helperText={false}
          />
          {transferType === "sftp" && (
            <TextInput
              className={classes.textField}
              label="Token"
              source={`${transferType}location.pk`}
              helperText={false}
              multiline
              format={(v) => v && v.replaceAll("$NEW_LINE$", "\n")}
            />
          )}
          <TextInput
            className={classes.textField}
            label="Path"
            source={`${transferType}location.destination_path`}
            helperText={false}
          />
          <BooleanInput
              className={classes.textField}
              label="Bundle Files"
              source="bundle_files"
              helperText={false}
          />
          {transferType === "ftp" && (
              <BooleanInput
                  className={classes.textField}
                  label="TLS"
                  source={`${transferType}location.tls`}
                  helperText={false}
              />
          )}
          <Confirm
            isOpen={openModal}
            title="Location Type Update"
            content="Are you sure you want to change the location type? This action will delete the data of the previous type."
            onConfirm={() => {
              setIsTypeChanged(true);
              setOpenModal(false);
            }}
            onClose={() => {
              setIsTypeChanged(false);
              setTransferType(locationData.type);
              setOpenModal(false);
            }}
          />
        </SimpleForm>
      </Edit>
    </div>
  );
};
