import { useState, useEffect, useCallback } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  PasswordInput,
  SelectInput,
  useDataProvider,
  useNotify,
  useRedirect,
  DeleteButton,
  Button,
  Toolbar,
  SaveButton,
} from "react-admin";
import { useHistory } from "react-router-dom";
import { EditWithToolbarStyle } from "../../style";
import Typography from "@material-ui/core/Typography";
import { pick, omit } from "lodash";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import EditPathMappings from "./EditPathMappings";
import { Drawer } from "@material-ui/core";

export const TranscoderToolbar = ({
  hasShow,
  basePath,
  hasCreate,
  hasEdit,
  hasList,
  isEdit = false,
  id,
  ...props
}) => {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const notify = useNotify();
  const handleClick = () => {
    redirect(`/${props.resource}`);
  };
  const classes = EditWithToolbarStyle();

  const handleSave = async (values) => {
    const newData = pick(values, "name", "type", "id");
    const previousData = pick(props.record, "name", "type", "id");
    await dataProvider
      .update("transcoder", {
        id: props.record.id,
        data: { ...newData },
        previousData: { ...previousData },
      })
      .then(({ data }) => {
        const newTranscoderconfig = omit(
          values.transcoderconfig,
          "pathmappings",
          "pathmappingsIds",
        );
        dataProvider
          .update("transcoderconfig", {
            id: values.id,
            data: { ...newTranscoderconfig },
            previousData: { ...props.record.transcoderconfig },
          })
          .then(({ data }) => {
            notify(`Transcoder updated`, "info");
            redirect(`/${props.resource}`);
          })
          .catch((error) => {
            notify(`${error}`, "warning");
          });
      })
      .catch((error) => {
        notify(`${error}`, "warning");
      });
  };

  return (
    <Toolbar {...props}>
      <SaveButton onSave={handleSave} className={classes.editButton} />
      <Button
        className={classes.cancelButton}
        icon={<ClearIcon />}
        label="Cancel"
        onClick={handleClick}
      />
      {isEdit && (
        <DeleteButton undoable={true} style={{ marginLeft: "auto" }} />
      )}
    </Toolbar>
  );
};

export const TranscoderEdit = (props) => {
  const classes = EditWithToolbarStyle();
  const dataProvider = useDataProvider();
  const [transcoderType, setTranscoderType] = useState(null);
  const [pathMappings, setPathMappings] = useState(null);
  const [isOpen, setIsOpen] = useState(null);
  const notify = useNotify();
  const history = useHistory();
  useEffect(() => {
    dataProvider
      .getOne("transcoder", { id: props.id })
      .then(({ data }) => {
        setPathMappings(data.transcoderconfig.pathmappings);
        setTranscoderType(data.type);
      })
      .catch((error) => {
        notify(`${error}`, "warning");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleDrawerSave = (pathMappings) => {
    setPathMappings(pathMappings);
    setIsOpen(false);
  };

  return (
    <div className={classes.card}>
      <a className={classes.breadcrumbLink} href="#/transcoder">
        {"< Transcoders"}
      </a>
      <Edit title="Edit Transcoder" {...props} actions={false}>
        <SimpleForm toolbar={<TranscoderToolbar isEdit={true} {...props} />}>
          <Typography variant="h6">Edit Transcoder</Typography>
          <TextInput
            className={classes.textField}
            source="name"
            helperText={false}
          />
          <SelectInput
            className={classes.textField}
            source="type"
            helperText={false}
            onChange={(e) => setTranscoderType(e.target.value)}
            optionValue="id"
            choices={[
              { id: "vantage", name: "Vantage" },
              { id: "elemental", name: "Elemental" },
            ]}
          />
          {transcoderType === "elemental" ? (
            <>
              <TextInput
                className={classes.textField}
                multiline
                source="transcoderconfig.host"
                label="Host (IP Address)"
                helperText={false}
              />
              <TextInput
                className={classes.textField}
                multiline
                source="transcoderconfig.user"
                label="Username"
                helperText={false}
              />
              <PasswordInput
                className={classes.textField}
                source="transcoderconfig.password"
                label="Password"
                helperText={false}
              />
            </>
          ) : (
            transcoderType === "vantage" && (
              <>
                <TextInput
                  className={classes.textField}
                  multiline
                  source="transcoderconfig.host"
                  label="Host (IP Address)"
                  helperText={false}
                />
                <TextInput
                  className={classes.textField}
                  label="Port"
                  source="transcoderconfig.port"
                  helperText={false}
                />
              </>
            )
          )}
          <div>
            <Button
              className={classes.editButton}
              style={{ marginTop: "14px", marginLeft: "0px" }}
              label="Pathmappings"
              onClick={() => setIsOpen(true)}
            >
              <EditIcon />
            </Button>
          </div>
        </SimpleForm>
      </Edit>
      <Drawer
        variant="persistent"
        open={isOpen}
        anchor="right"
        onClose={handleClose}
        style={{ width: "10rem" }}
        PaperProps={{ style: { width: "30%" } }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <EditPathMappings
          id={props.id}
          onCancel={handleClose}
          onSave={handleDrawerSave}
          resource={props.resource}
          basePath={props.basePath}
          pathMappings={pathMappings}
        />
      </Drawer>
    </div>
  );
};
