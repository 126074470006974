import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import PropTypes from "prop-types";
import * as React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    useCreate,
    useUpdateMany,
    useNotify,
    useRecordContext,
} from "react-admin";
import {triggerUpdateTI, uploadFile} from "../helpers";
import {createUpdateStyle} from "../style";
import {CustomToolbar} from "./CustomToolBar";
import {DestinationListInput} from "./DestinationListInput";
import {TrafficInstructionInput} from "./TrafficInstructionInput";
import {FormSpy} from "react-final-form";
import {useState} from "react";

export const TrafficUpdateDialog = (props) => {
    const resource = "job";
    const record = useRecordContext();
    const {handleTrafficUpdateClose, handleRefresh, ...rest} = props;
    const classes = createUpdateStyle();
    const [updateMany] = useUpdateMany();
    const [create] = useCreate();
    const notify = useNotify();
    const [formValues, setFormValues] = useState()
    const [ tiUploadFailed, setTiUploadFailed] = useState(false)

    const onSave = async (data) => {
        let tiFailed = false
        let { trafficinstructions } = record;
        let {filepath, destinations_values } = data
        let fileName = filepath.rawFile.name;
        //UPLOAD TI TO S3
        await uploadFile(
            `${window.REACT_APP_API_URL}/upload-traffic-instructions`,
            filepath,
        ).then(async (data)=>{
            await create(
                "trafficinstruction",
                {
                    filepath: data.key,
                    name: fileName,
                    version: (trafficinstructions.length + 1).toString(),
                    brs_job_id: record.id,
                },
                {
                    onSuccess: ({data}) => {
                        handleTrafficUpdateClose();
                        notify("Traffic Instruction Created Successfully", "info");
                    },
                    onFailure: (error) => {
                        notify(`${error}`, "warning");
                    }
                }
            );
        }).catch(error=>{
            notify(`${error}`,"warning")
            tiFailed = true
        });

        if(tiFailed){
            return
        }

        notify("Traffic Instructions Uploaded Successfully", "info");

        const destinationsToDeleteIds = []
        const notificationsToUpdate = []
        const destinationsToDelete = []
        const newDestinationsIds = []
        record.destinations.filter(d => d.active).map(destination => {
            if (destinations_values && !destinations_values.includes(destination.destination.id)) {
                destinationsToDeleteIds.push(destination.id)
                destination.active = false
                destinationsToDelete.push({id: destination.id, active: false})
            } else {
                newDestinationsIds.push(destination.id)
                if (destination.notifications.length > 0) {
                    let notification = destination.notifications.find(n => n.latest === true)
                    if (notification) {
                        notification.latest = false
                        notificationsToUpdate.push(notification)
                    }
                }
            }
        })

        if (notificationsToUpdate.length > 0) {
            const notificationsToUpdateIds = notificationsToUpdate.map(n => n.id)
            await updateMany(
                "brsnotification",
                notificationsToUpdateIds,
                notificationsToUpdate,
                {
                    onSuccess: ({data}) => {
                        notify("Notifications Updated", "info");
                    },
                    onFailure: (error) => {
                        notify(`${error}`, "warning");
                    }
                }
            );
        }

        const newNotification = {
            latest: true,
            status: "Pending",
            progress: 0,
            traffic_instruction_version: (trafficinstructions.length + 1).toString()
        }

        if (destinationsToDelete.length > 0) {
            await updateMany(
                "brsjobdestination",
                destinationsToDeleteIds,
                destinationsToDelete,
                {
                    onSuccess: ({data}) => {
                        handleTrafficUpdateClose();
                        handleRefresh();
                        notify("Destinations Drop Off Updated", "info");
                    },
                    onFailure: (error) => {
                        notify(`${error}`, "warning");
                        handleTrafficUpdateClose();
                    }
                }
            );
        }

        notify("Destinations Updated", "info");

        for (let index = 0; index < newDestinationsIds.length; index++) {
            newNotification.brsjobdestination_id = newDestinationsIds[index]
            create('brsnotification', newNotification)
        }

        await triggerUpdateTI(data.id, record.job_id)
        handleTrafficUpdateClose();
    }

    return (
        <Dialog open={true} onClose={handleTrafficUpdateClose} fullWidth maxWidth="lg">
            <DialogTitle>Traffic Update</DialogTitle>
            <DialogContent>
                <Card>
                    <CardHeader>Traffic Update</CardHeader>
                    <CardContent>
                        <Edit className={classes.textField} resource={resource} redirect={false} actions={false}
                              id={record.id} {...rest}>
                            <SimpleForm toolbar={<CustomToolbar className={classes.modalToolbar}
                                                                isDisabled={!formValues?.filepath || formValues?.disabled_button}
                                                                handleCancelClick={handleTrafficUpdateClose}
                                                                saveButtonExtraProps={{onSave}}/>}>
                                <Card style={{
                                    display: "flex",
                                    width: "100%",
                                    margin: "10px 0",
                                    justifyContent: "flex-start"
                                }}>
                                    <TextInput
                                        className={classes.textField}
                                        source="request_id"
                                        label="Request ID"
                                        style={{width: '37%', marginRight: "10rem"}}
                                        aria-readonly={true}
                                        disabled={true}
                                    />
                                    <TextInput
                                        className={classes.textField}
                                        source="estimate_id"
                                        label="Estimate ID"
                                        style={{width: '37%'}}
                                        aria-readonly={true}
                                        disabled={true}
                                    />
                                </Card>
                                <Card style={{
                                    display: "flex",
                                    width: "100%",
                                    margin: "10px 0",
                                    justifyContent: "space-between"
                                }}>
                                    <DestinationListInput
                                        brsDestinations={record.destinations.filter(destination => destination.active === true)}
                                        style={{width: '45%'}}
                                        showDestinationTextArea={false}
                                        source="destinations"
                                        resource="destination"
                                        sort={{field: "call_letters", order: "ASC"}}
                                        canDelete={true}
                                        isCreate={false}
                                    />
                                    <TrafficInstructionInput source="trafficinstructions" style={{width: '45%'}}/>
                                </Card>
                                <FormSpy onChange={state => setFormValues(state.values)}/>
                            </SimpleForm>
                        </Edit>
                    </CardContent>
                </Card>
            </DialogContent>
        </Dialog>
    );
}

TrafficUpdateDialog.propTypes = {
    handleTrafficUpdateClose: PropTypes.func,
    handleRefresh: PropTypes.func,
}
