import { makeStyles } from "@material-ui/core/styles";
export const createUpdateStyle = makeStyles({
  textField: {
    width: "98%",
  },
  "& svg": {
    color: "red",
  },
  link: {
    color: "#3CE7EA",
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: 400,
    textDecoration: "underline",
  },
  breadcrumbLink: {
    color: "#3CE7EA",
    fontSize: "16px",
    fontFamily: "sans-serif, Roboto",
    fontWeight: 400,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  card: {
    width: "55%",
    marginTop: "24px",
  },
  switch: {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#3CE7EA",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#3CE7EA",
    },
  },
  statusButton: {
    borderRadius: "50%",
    backgroundColor: "green",
    color: "white",
    minWidth: "26px",
    padding: 0,
    height: "26px",
    "&:hover": {
      backgroundColor: "darkgreen",
    },
  },
  modalToolbar: {
    backgroundColor: "initial",
    justifyContent: "end",
  },
  showButton: {
    color: "#3CE7EA",
    border: "1px solid #3CE7EA",
  },
  button: {
    backgroundColor: "#3CE7EA",
    color: "#2A2A2A",
    borderRadius: "unset",
    padding: "4px 8px 4px 8px",
    textTransform: "none",
    fontSize: "14px",
    opacity: 0.9,
    "&:hover": {
      backgroundColor: "#3CE7EA",
      color: "#2A2A2A",
      opacity: 1,
      boxShadow: "none",
    },
  },
});

export const buttonStyle = makeStyles({
  button: {
    backgroundColor: "#3CE7EA",
    color: "#2A2A2A",
    borderRadius: "unset",
    padding: "4px 8px 4px 8px",
    textTransform: "none",
    fontSize: "14px",
    opacity: 0.9,
    "&:hover": {
      backgroundColor: "#3CE7EA",
      color: "#2A2A2A",
      opacity: 1,
      boxShadow: "none",
    },
    "&.Mui-disabled": {
      color: "#484646",
      backgroundColor: "#3c9191",
      pointerEvents: "auto",
      cursor: "not-allowed",
    },
  },
});

export const EditWithToolbarStyle = makeStyles({
  textField: {
    width: "98%",
  },
  link: {
    color: "#3CE7EA",
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: 400,
  },
  breadcrumbLink: {
    color: "#3CE7EA",
    fontSize: "16px",
    fontFamily: "sans-serif, Roboto",
    fontWeight: 400,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  cancelButton: {
    backgroundColor: "transparent",
    marginLeft: "16px",
    border: "1px solid #3CE7EA",
    color: "#3CE7EA",
    borderRadius: "unset",
    padding: "4px 8px 4px 8px",
    opacity: 0.9,
    "&:hover": {
      backgroundColor: "#3CE7EA",
      color: "#2A2A2A",
      opacity: 1,
      boxShadow: "none",
    },
    "& svg": {
      marginRight: "6px",
    },
  },
  editButton: {
    backgroundColor: "#3CE7EA",
    color: "#2A2A2A",
    borderRadius: "unset",
    padding: "4px 8px 4px 8px",
    opacity: 0.9,
    "&:hover": {
      backgroundColor: "#3CE7EA",
      color: "#2A2A2A",
      opacity: 1,
      boxShadow: "none",
    },
    "& svg": {
      marginRight: "6px",
    },
  },
  button: {
    backgroundColor: "#3CE7EA",
    color: "#2A2A2A",
    borderRadius: "unset",
    padding: "4px 8px 4px 8px",
    textTransform: "none",
    fontSize: "14px",
    opacity: 0.9,
    "&:hover": {
      backgroundColor: "#3CE7EA",
      color: "#2A2A2A",
      opacity: 1,
      boxShadow: "none",
    },
  },
  card: {
    width: "42%",
    marginTop: "24px",
  },
});

export const EditPathMappingsStyle = makeStyles({
  inputWithDeleteIcon: {
    display: "flex",
    width: "100%",
  },
  formControl: {
    display: "flex",
    flex: "1 auto",
    flexDirection: "row",
    alignItems: "baseline",
    width: "100%",
    marginBottom: "8px",
    marginLeft: "10px",
  },
  selectInput: {
    display: "flex",
    alignItems: "center",
    marginBottom: "12px",
    justifyContent: "space-between",
    width: "100%",
  },
  textField: {
    width: "98%",
  },
  outlinedButton: {
    backgroundColor: "transparent",
    marginLeft: "16px",
    border: "1px solid #3CE7EA",
    color: "#3CE7EA",
    borderRadius: "unset",
    padding: "4px 8px 4px 8px",
    opacity: 0.9,
    "&:hover": {
      backgroundColor: "#3CE7EA",
      color: "#2A2A2A",
      opacity: 1,
      boxShadow: "none",
    },
    "&:disabled,button[disabled]": {
      borderColor: "#ffffff1f",
    },
    "& svg": {
      marginRight: "6px",
    },
  },
  button: {
    backgroundColor: "#3CE7EA",
    color: "#2A2A2A",
    borderRadius: "unset",
    padding: "4px 8px 4px 8px",
    opacity: 0.9,
    "&:hover": {
      backgroundColor: "#3CE7EA",
      color: "#2A2A2A",
      opacity: 1,
      boxShadow: "none",
    },
    "& svg": {
      marginRight: "6px",
      fontSize: "18px",
    },
  },
  deleteButton: {
    position: "relative",
    top: "20px",
    right: "0px",
    backgroundColor: "transparent",
    color: "#e57373",
    opacity: 0.7,
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.08)",
      opacity: 1,
      boxShadow: "none",
    },
    "& svg": {
      fontSize: "24px",
    },
  },
});

export const loginStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    backgroundColor: "#303030",
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    width: "100vw",
  },
  loginPageWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
    backgroundColor: "#303030",
  },
  cadentLogo: {
    width: "500px",
    margin: "100px auto 50px auto",
    padding: "10px",
    borderRadius: "20px",
  },
  loginForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  loginField: {
    // width: "350px",
    // margin: "0 0 16px 0", // Matching React-admin's spacing for form inputs
    "& .MuiInputBase-root": {
      backgroundColor: "#5A5A5A",
      borderBottom: "2px solid #aaa",
      borderRadius: "4px",
      "&:hover": {
        backgroundColor: "#6B6B6B",
        borderColor: "#aaa",
      },
      "&.Mui-focused": {
        borderColor: "#3CE7EA",
      },
    },
    "& .MuiInputBase-input": {
      color: "#FFFFFF",
    },
    // "& .MuiOutlinedInput-root": {
    //   "& fieldset": {
    //     borderColor: "#d9d9d9", // Light gray border matching default theme
    //   },
    //   "&:hover fieldset": {
    //     borderColor: "#aaa", // Darken border on hover
    //   },
    //   "&.Mui-focused fieldset": {
    //     borderColor: "#3CE7EA", // Primary color on focus
    //   },
    // },
    "& .MuiInputLabel-root": {
      color: "#CECECE", // Default label color in React-admin
      //fontSize: "14px", // Similar font size for labels
    },
    // "& .MuiFormHelperText-root": {
    //   color: "#f44336", // Error color to match invalid input feedback
    // },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  card: {
    alignItems: "center",
    backgroundColor: "#424242",
    borderRadius: "5px",
    boxShadow: "0px 9px 10px #00000029",
    display: "flex",
    flexDirection: "column",
    height: "280px",
    justifyContent: "center",
    paddingTop: "30px",
  },
  title: {
    color: "darkgrey",
    font: "900 52px Museo Sans, sans-serif",
    textTransform: "uppercase",
  },
  header: {
    color: "grey",
    font: "normal 18px Roboto, sans-serif",
    padding: "10px 0 0 0",
    textTransform: "capitalize",
  },
  content: {
    padding: "16px 18px",
  },
  link: {
    alignSelf: "flex-start",
    color: "purple",
    display: "flex",
    font: "normal 14px/16px Roboto, sans-serif",
    padding: "10px 0",
  },
  loginButton: {
    margin: "1em 0 0 0",
    backgroundColor: "transparent",
    border: "1px solid #3CE7EA",
    color: "#3CE7EA",
    borderRadius: "unset",
    padding: "4px 8px 4px 8px",
    opacity: 0.9,
    "&:disabled": {
      border: "1px solid #303030",
    },
    "&:hover": {
      backgroundColor: "#3CE7EA",
      color: "#2A2A2A",
      opacity: 1,
      boxShadow: "none",
    },
  },
  logo: {
    textAlign: "center",
    fontWeight: 900,
    color: "#F6F9FA",
    textTransform: "uppercase",
    fontSize: "24px",
    marginBottom: "16px",
  },
}));

export const fileInputStyle = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: theme.spacing(65.5),
  },
  fileInput: {
    "& [class*='RaFileInput-dropZone']": {
      height: theme.spacing(45.5),
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
    "& [class*='RaFileInput-removeButton']": {
      padding: "0 2rem",
      "& svg": {
        "& path": {
          d: "path('M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z')",
        },
      },
    },
    "& .previews": {
      height: theme.spacing(12),
    },
    "& .previews a": {
      color: "#3CE7EA",
    },
  },
}));
