import { Edit, SimpleForm, TextInput, ReferenceInput } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { CustomToolbar } from "../../components/CustomToolBar";
import { createUpdateStyle } from "../../style";

export const InventorySourceEdit = (props) => {
  const classes = createUpdateStyle();
  return (
    <div style={{ width: "42%", height: "50%", marginTop: "24px" }}>
      <a className={classes.breadcrumbLink} href="#/Inventory Source">
        {"< Inventory Sources"}
      </a>
      <Edit title="Edit Inventory Source" {...props} actions={false}>
        <SimpleForm toolbar={<CustomToolbar isEdit={true} {...props} />}>
          <Typography variant="h6">Edit Inventory Source</Typography>
          <TextInput
            source="name"
            className={classes.textField}
            helperText={false}
          />
        </SimpleForm>
      </Edit>
    </div>
  );
};
