import { Datagrid, List, TextField } from "react-admin";
import { FilterList, ListActions, CustomPagination } from "../../components";

export const EmailTemplateList = (props) => {
  return (
      <List
          {...props}
          perPage={100}
          actions={<ListActions label="New Email Template"/>}
          pagination={<CustomPagination rowsPerPageOptions={[25, 50, 100, 250, 500]}/>}
          filters={<FilterList source="subject"/>}
      >
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <TextField source="type"/>
          <TextField source="subject"/>
        </Datagrid>
      </List>
  );
};
