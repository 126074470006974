import { Datagrid, List, TextField } from "react-admin";
import { CustomPagination } from "../../components/CustomPagination";
import { useRecordContext } from "react-admin";
import { FilterList, ListActions } from "../../components/index";

export const CustomTextField = (props) => {
  const record = useRecordContext(props);
  let source = props.source;
  if (props.source === "host") {
    source = props.record.type === "local" ? "url" : "host";
  }
  return (
    <span>
      {record[`${record.type}location`] &&
        record[`${record.type}location`][source]}
    </span>
  );
};
export const LocationList = (props) => {
  return (
    <List
      {...props}
      perPage={100}
      actions={<ListActions />}
      pagination={<CustomPagination rowsPerPageOptions={[25, 50, 100, 250, 500]}/>}
      filters={<FilterList />}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" label="ID" />
        <TextField source="name" />
        <CustomTextField source="host" label="Host Name" />
        <TextField source="type" style={{ textTransform: "uppercase" }} />
        <CustomTextField source="destination_path" label="Path" />
      </Datagrid>
    </List>
  );
};
