import {
  Create,
  SimpleForm,
  TextInput,
} from "react-admin";
import { createUpdateStyle } from "../../style";
import Typography from "@material-ui/core/Typography";
import { CustomToolbar } from "../../components/CustomToolBar";

export const InventorySourceCreate = (props) => {
  const classes = createUpdateStyle();
  return (
      <div className={classes.card}>
        <a className={classes.breadcrumbLink} href="#/Inventory Source">
          {"< Inventory Sources"}
        </a>
        <Create title="Add Inventory Source" {...props}>
          <SimpleForm redirect="list" toolbar={<CustomToolbar {...props} />}>
            <Typography variant="h6">Add Inventory Source</Typography>
            <TextInput
                source="name"
                className={classes.textField}
                helperText={false}
            />
          </SimpleForm>
        </Create>
      </div>
  );
};
