import { Card } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";
import * as React from "react";
import { useCallback, useState } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Button,
  Create,
  DateInput,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useDataProvider,
} from "react-admin";
import {
  DestinationListInput,
  TrafficInstructionInput,
} from "../../components";
import { alphanumeric } from "../../helpers";
import { createUpdateStyle } from "../../style";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  BRSCustomToolbar,
  EstimateIdInput,
  IsciInput,
  OriginalIsciInput,
  SlateTypeInput,
} from "../../components/BrsFormComponents";
import { useForm } from "react-final-form";
import { valueFromAST } from "graphql";

export const BrsCreate = (props) => {
  const classes = createUpdateStyle();
  const [validatingIsci, setValidatingIsci] = useState(true);
  const [validatingOriginalIsci, setValidatingOriginalIsci] = useState(true);
  const [originalIscisDisabled, setOriginalIscisDisabled] = useState({});
  const [unitRotation, setUnitRotation] = useState("unit");
  const [includeSD, setIncludeSD] = useState({});
  const handleIncludeSDChange = useCallback(
    (field) => async (value) => {
      setIncludeSD({ ...includeSD, [field]: value });
    },
    [includeSD],
  );
  const [disabled, setDisabled] = useState(false);

  const validateOriginalIscis = (data) => {
    if (data?.isciasset?.original_iscis?.length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  return (
    <div className={classes.card} style={{ width: "100%" }}>
      <a className={classes.breadcrumbLink} href="#/brs">
        {"< BRS Jobs"}
      </a>
      <Create
        title="Add BRS Job"
        resource="brsjob"
        basePath={"/brs"}
        {...props}
      >
        <SimpleForm
          redirect="list"
          toolbar={
            <BRSCustomToolbar
              {...props}
              tiUpdate={false}
              saveButtonExtraProps={{ disabled }}
            />
          }
          validateOnBlur={true}
        >
          <Typography variant="h6">New Request</Typography>
          <Card style={{ display: "flex", width: "100%" }}>
            <TextInput
              validate={[required()]}
              className={classes.textField}
              source="request_id"
              label="Request ID"
              style={{ marginRight: "2rem", width: "25%" }}
            />
            <EstimateIdInput
              validate={[required()]}
              className={classes.textField}
              source="estimate_id"
              label="Estimate ID"
              style={{ marginRight: "2rem", width: "25%" }}
            />
            <SlateTypeInput
              source="slate_type"
              validate={[required()]}
              choices={[
                { id: "CADENT", name: "Cadent" },
                { id: "CROSS", name: "Cross" },
                {
                  id: "FLUIDITY",
                  name: "Fluidity",
                },
              ]}
              className={classes.textField}
              defaultValue="CADENT"
              style={{ marginRight: "2rem", width: "25%" }}
            />
            <SelectInput
              source="market_type"
              validate={[required()]}
              choices={[
                { id: "LOCAL", name: "Local" },
                { id: "NATIONAL", name: "National" },
              ]}
              className={classes.textField}
              defaultValue="LOCAL"
              style={{
                marginRight: "2rem",
                width: "25%",
              }}
            />
          </Card>
          <Card style={{ display: "inline-flex", width: "100%" }}>
            <div style={{ marginRight: "2rem", width: "23%" }}>
              <ReferenceInput
                source="inventory_source_id"
                label="Inventory Source"
                perPage={500}
                sort={{ field: "name", order: "ASC" }}
                className={classes.textField}
                validate={[required()]}
                reference="inventorysource"
                filterToQuery={(searchText) => ({ name: searchText })} // Enable filtering based on the input
              >
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
            </div>
            <div style={{ marginRight: "2rem", width: "23%" }}>
              <SelectInput
                source="unit_rotation"
                choices={[
                  { id: "unit", name: "Unit" },
                  { id: "rotation", name: "Rotation" },
                ]}
                onChange={(e) => setUnitRotation(e.target.value)}
                defaultValue="unit"
                label="Unit/Rotation"
                className={classes.textField}
                validate={[required()]}
              />
            </div>
          </Card>
          <Card style={{ width: "98.6%", padding: 10 }} variant="outlined">
            <ArrayInput
              source="brs_spots"
              initialValue={[{}]}
              label=""
              validate={[required()]}
            >
              <SimpleFormIterator
                TransitionProps={{ enter: false, exit: false }}
                variant="filled"
                addButton={
                  <Button label="ra.action.add" style={{ color: "#3CE7EA" }}>
                    <AddIcon />
                  </Button>
                }
                removeButton={
                  <Button
                    label="ra.action.remove"
                    style={{ color: "#e57373", marginTop: 12, marginRight: 8 }}
                  >
                    <CloseIcon />
                  </Button>
                }
              >
                <FormDataConsumer>
                  {({ getSource, scopedFormData }) => {
                    if (unitRotation === "rotation" && scopedFormData?.unit) {
                      scopedFormData.unit = "";
                    } else if (
                      unitRotation === "unit" &&
                      scopedFormData?.rotation
                    ) {
                      scopedFormData.rotation = "";
                    }
                    return (
                      <>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            marginTop: "8px",
                          }}
                        >
                          <DateInput
                            className={classes.textField}
                            validate={[required()]}
                            source={getSource("start_date")}
                            record={scopedFormData}
                            label="Start Date"
                            style={{ marginRight: "2rem", width: "20%" }}
                            InputProps={{
                              style: {
                                "::-webkit-calendar-picker-indicator": {
                                  color: "red",
                                },
                              },
                            }}
                          />
                          <DateInput
                            className={classes.textField}
                            validate={[required()]}
                            source={getSource("end_date")}
                            record={scopedFormData}
                            label="End Date"
                            style={{ marginRight: "2rem", width: "20%" }}
                          />
                          <TextInput
                            source={getSource("title")}
                            record={scopedFormData}
                            label="Title"
                            style={{ marginRight: "2rem", width: "20%" }}
                            disabled={false}
                          />
                        </div>
                        <div style={{ display: "flex", width: "100%" }}>
                          <IsciInput
                            className={classes.textField}
                            validate={[required(), alphanumeric]}
                            source={getSource("isciasset.isci")}
                            record={scopedFormData}
                            label="ISCI"
                            style={{ marginRight: "2rem", width: "25%" }}
                            setValidatingIsci={setValidatingIsci}
                            setOriginalIscisDisabled={setOriginalIscisDisabled}
                            originalIscisDisabled={originalIscisDisabled}
                            originalIscisField={getSource("isciasset.isci")}
                            duration={getSource("isciasset.duration")}
                            originaliscis={getSource(
                              "isciasset.original_iscis",
                            )}
                            isciAssetSource={getSource("isciasset")}
                          />
                          <SelectInput
                            source={getSource("isciasset.sigma_encoding")}
                            choices={[
                              { id: "NONE", name: "None" },
                              { id: "CADENT", name: "Cadent" },
                              {
                                id: "TTWN",
                                name: "TTWN",
                              },
                            ]}
                            defaultValue="CADENT"
                            label="Sigma Encoding"
                            className={classes.textField}
                            validate={[required()]}
                            style={{ marginRight: "2rem", width: "25%" }}
                            disabled={
                              scopedFormData &&
                              scopedFormData["isciasset"]?.exists
                            }
                          />
                          <SelectInput
                            choices={[{ id: "NO", name: "No" }]}
                            defaultValue="NO"
                            label="BVS Encoding"
                            className={classes.textField}
                            validate={[required()]}
                            source={getSource("isciasset.bvs_encoding")}
                            style={{ marginRight: "2rem", width: "25%" }}
                            disabled={true}
                          />
                          <NumberInput
                            className={classes.textField}
                            style={{ display: "none" }}
                            source={getSource("isciasset.duration")}
                            record={scopedFormData}
                            helperText={false}
                            initialValue={0}
                          />
                          <TextInput
                            className={classes.textField}
                            validate={[required()]}
                            source={getSource(unitRotation)}
                            record={scopedFormData}
                            label={unitRotation}
                            style={{ width: "12%", minWidth: "60px" }}
                            onChange={(e) => {
                              scopedFormData[unitRotation] = e.target.value;
                            }}
                            InputProps={{
                              endAdornment:
                                unitRotation === "rotation" ? (
                                  <InputAdornment position="start">
                                    %
                                  </InputAdornment>
                                ) : null,
                            }}
                          />
                          <BooleanInput
                            defaultValue={true}
                            className={classes.switch}
                            source={getSource("isciasset.include_sd")}
                            record={scopedFormData}
                            validate={[required()]}
                            onChange={handleIncludeSDChange(
                              getSource("include_sd"),
                            )}
                            label="Include SD"
                            style={{
                              margin: "1rem 2rem 0rem 2rem",
                              width: "25%",
                            }}
                            disabled={
                              scopedFormData &&
                              scopedFormData["isciasset"]?.exists
                            }
                          />
                        </div>
                        <div style={{ display: "flex", width: "96%" }}>
                          <OriginalIsciInput
                            source={getSource("isciasset.original_iscis")}
                            validate={[validateOriginalIscis(scopedFormData)]}
                            nestedLabel="Original ISCI"
                            maxLength={4}
                            disabled={
                              originalIscisDisabled.hasOwnProperty(
                                getSource("isciasset.isci"),
                              )
                                ? originalIscisDisabled[
                                    getSource("isciasset.isci")
                                  ]
                                : true
                            }
                            setValidatingOriginalIsci={
                              setValidatingOriginalIsci
                            }
                            getSource={getSource}
                            durationSource={getSource("isciasset.duration")}
                            isciAssetExists={
                              scopedFormData &&
                              scopedFormData["isciasset"]?.exists
                            }
                          />
                        </div>
                      </>
                    );
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </Card>
          <Card style={{ display: "flex", width: "100%", margin: "10px 0" }}>
            <DestinationListInput
              brsDestinations={[]}
              style={{ width: "50%" }}
              source="destinations"
              resource="destination"
              sort={{ field: "call_letters", order: "ASC" }}
              canDelete={true}
              isCreate={true}
            />
            <TrafficInstructionInput
              source="trafficinstructions"
              style={{ width: "50%" }}
            />
          </Card>
        </SimpleForm>
      </Create>
    </div>
  );
};
