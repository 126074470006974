import { useState } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  PasswordInput,
  SelectInput,
} from "react-admin";
import { createUpdateStyle } from "../../style";
import Typography from "@material-ui/core/Typography";
import { CustomToolbar } from "../../components/CustomToolBar";

export const TranscoderCreate = (props) => {
  const classes = createUpdateStyle();
  const [transcoderType, setTranscoderType] = useState(null);
  return (
    <div className={classes.card}>
      <a className={classes.breadcrumbLink} href="#/transcoder">
        {"< Transcoders"}
      </a>
      <Create title="Add Transcoder" {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar {...props} />}>
          <Typography variant="h6">Add Transcoder</Typography>
          <TextInput
            className={classes.textField}
            source="name"
            helperText={false}
          />
          <SelectInput
            className={classes.textField}
            source="type"
            helperText={false}
            onChange={(e) => setTranscoderType(e.target.value)}
            optionValue="id"
            choices={[
              { id: "vantage", name: "Vantage" },
              { id: "elemental", name: "Elemental" },
            ]}
          />
          {transcoderType === "vantage" ? (
            <>
              <TextInput
                className={classes.textField}
                multiline
                source="transcoderconfig.host"
                label="Host (IP Address)"
                helperText={false}
              />
              <TextInput
                className={classes.textField}
                label="Port"
                source="transcoderconfig.port"
                helperText={false}
              />
            </>
          ) : (
            transcoderType === "elemental" && (
              <>
                <TextInput
                  className={classes.textField}
                  multiline
                  source="host"
                  label="Host (IP Address)"
                  helperText={false}
                />
                <TextInput
                  className={classes.textField}
                  multiline
                  source="user"
                  label="Username"
                  helperText={false}
                />
                <PasswordInput
                  className={classes.textField}
                  source="password"
                  label="Password"
                  helperText={false}
                />
              </>
            )
          )}
        </SimpleForm>
      </Create>
    </div>
  );
};
