import { makeStyles } from '@material-ui/core';
import AddIcon from "@material-ui/icons/AddCircleOutline";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";
import PropTypes from 'prop-types';
import * as React from 'react';
import { useState } from 'react';
import { Button, TextInput } from 'react-admin';
import { useForm } from "react-final-form";


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(1),
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
    width: theme.spacing(40),
  },
  input: {
    marginRight: theme.spacing(1),
  },
  addButton: {
    marginTop: theme.spacing(1),
    color: "#3CE7EA",
    marginBottom: theme.spacing(1),
    width: theme.spacing(4),
  },
  deleteButton: {
    color: "#e57373",
  }
}));

export const NestedArrayInput = ({
  source,
  nestedLabel,
  disabled,
  error,
  maxLength,
  validatingIsci,
  defaultValue = [],
  onBlur = (index, items, setItems) => {},
  nestedValidate = [],
  validate = [],
  bottomComponent = null,
  onRemove = index => {},
}) => {
  const classes = useStyles();
  const form = useForm();
  const [items, setItems] = useState(form.getFieldState(source) && form.getFieldState(source).value ? form.getFieldState(source).value : defaultValue);

  const handleItemChange = (index, value) => {
    const newItems = [...items];
    newItems[index] = value;
    setItems(newItems);
  };

  const handleAddItem = () => {
    const newItems = [...items, ''];
    form.change(source, newItems);
    setItems(newItems);
  };

  const handleRemoveItem = index => {
    const newItems = [...items];
    newItems.splice(index, 1);
    onRemove(index);
    form.change(source, newItems);
    setItems(newItems);
  };

  return (
    <div className={classes.root}>
      {items.map((item, index) => (
        <div className={classes.item} key={index}>
          <TextInput
            className={classes.input}
            label={`${nestedLabel} ${index + 1}`}
            source={`${source}.${index}`}
            value={item}
            inputProps={{value: item}}
            onChange={(event) => handleItemChange(index, event.target.value)}
            helperText={validatingIsci.value && validatingIsci.index === index ? "Checking ISCI..." : ""}
            validate={[...validate, ...nestedValidate.map((validateFunc) => validateFunc(index))]}
            disabled={disabled}
            onBlur={onBlur(index, items, setItems)}
            error={error}
          />
          {items.length > 1 && (
            <Button label="ra.action.remove" disabled={validatingIsci.value || disabled} className={classes.deleteButton} onClick={() => handleRemoveItem(index)}>
              <CloseIcon/>
            </Button>
          )}
        </div>
      ))}
      {bottomComponent}
      {items.length < maxLength && (
        <Button label="ra.action.add" className={classes.addButton} onClick={handleAddItem} disabled={validatingIsci.value || disabled}>
          <AddIcon/>
        </Button>
      )}
    </div>
  );
}

NestedArrayInput.propTypes = {
  source: PropTypes.string.isRequired,
  nestedLabel: PropTypes.string,
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),
  nestedValidate: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  maxLength: PropTypes.number,
  validatingIsci: PropTypes.bool,
  bottomComponent: PropTypes.element,
  defaultValue: PropTypes.array,
  onRemove: PropTypes.func,
}
